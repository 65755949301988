import styled from 'styled-components'

export const StepText = styled.p`
  font-size: ${(props) => props.size || ' 48px'};
  color: ${(props) => props.color || '#494e9d'};
  font-weight: ${(props) => props.weight || '700'};
  line-height: ${(props) => props.lineHeight || ''};
`
export const StepContent = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width || '155px'};
  height: ${(props) => props.height || '111px'};
  align-items: center;
  flex-direction: column;
`
export const StepSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-item: center;
  margin-top: 10px;
`
export const CountText = styled.p`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  top: ${(props) => props.top || '138px'};
  font-size: ${(props) => props.size || '16px'};
  color: ${(props) => props.color || ' #E0DEFC'};
  font-weight: ${(props) => props.weight || '600'};
  line-height: 22px;
  font-family: 'Nunito Sans';
  font-style: normal;
  left: ${(props) => props.left || '0px'};
`
export const GfitRunLogo = styled.img``
export const ProgressSection = styled.div`
  width: 100%;
  background-color: ${(props) => props.backGroundColor || 'white'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
`
export const Graph = styled.div`
  display: flex;
  width: 343px;
  height: 295px;
  justify-content: center;
  position: absolute;
  top: 450px;
  background-color: white;
  margin: 0px 16px 16px 16px;
  border-radius: 8px;
`
export const BarSection = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -264px;
`
export const Activities = styled.div`
  display: flex;
  // width: 100%;
  // justify-content: space-around;
  margin: 10px 0px 10px 0px;
`
export const ChartContainer = styled.div`
  width: 343px;
  height: 295px;
  background: white;
  box-shadow: 8px 7px 25px rgb(0 0 0 / 5%);
  border-radius: 8px;
`
export const TabButton = styled.button`
  width: 114px;
  height: 36px;
  border-radius: 200px;
  border: none;
  background-color: ${(props) => props.color || 'white'};
  color: ${(props) => props.textColor || '#5A5A5A'};
`
export const Card = styled.div`
  width: 163.5px;
  height: 106px;
  background-color: white;
  border-radius: 8px;
  position: relative;
  margin: ${(props) => props.margin || ''};
`
export const CardContent = styled.div`
  position: absolute;
  top: 11px;
  left: 22px;
`
export const Logo = styled.img`
  position: ${(props) => props.position || ''};
  left: ${(props) => props.left || ''};
  top: ${(props) => props.top || ''};
`
export const CardText = styled.p`
  position: absolute;
  top: ${(props) => props.top || '53px'};
  left: ${(props) => props.left || '19px'};
  font-size: ${(props) => props.size || '20px'};
  font-weight: ${(props) => props.weight || '700'};
  line-height: ${(props) => props.height || '20px'};
  color: ${(props) => props.color || '#313131'};
`
export const Arrow = styled.button`
  border: none;
  background-color: white;
  transition-duration: 0.8s;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  &:active {
    width: 30px;
    height: 30px;
    background-color: #bdbdbd;
    border-radius: 50%;
    transition: all 0.1s;
  }
`
export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-around;
  align-items: center;
`
export const DateTextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 217px;
`
export const CountLoader = styled.div`
  text-align: center;
  height: 111px;
  width: 322px;
`
export const StepTrack = styled.div`
  display: flex;
  // width: 100%;
  justify-content: center;
  margin-top: -6px;
`
export const ChartBody = styled.div`
  padding-top: 22px;
`
export const ChartHeader = styled.div``
