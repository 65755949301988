import Skeleton from 'react-loading-skeleton';

function FlareUpLogLoader() {
    const dummyArray = Array.from({ length: 6 }, (_, i) => i + 1);
    const skeletonWidths = [150, 100, 150, 100];

    return (
        dummyArray.map((d) => (
            <dl key={d} className="flare-up-log-card">
                {skeletonWidths.map((width) => (
                    <div key={width}>
                        <dd><Skeleton width={width} /></dd>
                    </div>
                ))}
            </dl>
        ))
    );
}

export default FlareUpLogLoader;