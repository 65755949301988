import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import RecommendProgram from './recommend-program'
import AllProgramLoader from './all-program-loader'
import Left from '../../../assets/images/left.svg'
import wordPressAPi from '../../../utils/wordPressApi'

function AllPrograms() {
  const navigate = useNavigate()
  const location = useLocation()
  const { programDetails, policyId } = location.state
  const [loading, setLoading] = useState(true)
  const [programList, setProgramList] = useState('')

  useEffect(() => {
    smartech('dispatch', 'Page Browse', {
      pageurl: window.location.href,
    })
    wordPressAPi.get(`/api/policy-programs/${policyId}`).then((res) => {
      if (res?.data?.status_code === 200) {
        setProgramList(res?.data?.data)
        setLoading(false)
      }
    })
  }, [])

  const handleProgramCardClick = ({
    is_partnered_program: partneredBy,
    number_of_weeks: numberOfWeeks,
    _id: wordPressProgramId,
    wp_program: {
      title,
      acf: {
        program_overview: programOverviewDetails,
        short_description: programDescription,
        price,
        discounted_price: discountedPrice,
        partnered_by: { name: partnerName },
        background_image: { url: backGroundImage },
      },
    },
  }) => {
    if (programOverviewDetails) {
      navigate('/program-details', {
        state: {
          title,
          numberOfWeeks,
          partneredBy,
          wordPressProgramId,
          programDetails,
          programOverviewDetails,
          programDescription,
          price,
          discountedPrice,
          partnerName,
          backGroundImage
        },
      })
    }
  }

  return (
    <React.Fragment>
      <div className="all-programs-header d-flex">
        <div className="back-arrow-container d-flex justify-content-center align-items-center">
          <img
            alt="left arrow"
            className="back-arrow"
            role="presentation"
            src={Left}
            onClick={() =>
              setTimeout(() => {
                navigate('/callback')
              }, 300)
            }
          />
        </div>
        <div className="all-programs-text f-20 fw-700">Select your Program</div>
      </div>

      {loading ? (
        <div>
          {' '}
          <AllProgramLoader /> <AllProgramLoader /> <AllProgramLoader />{' '}
          <AllProgramLoader /> <AllProgramLoader />
        </div>
      ) : (
        <div className="program-list-card">
          <p className="indigo-blue f-16 fw-600 ps-1 ms-3 mt-4">
            Available Programs
          </p>


          {programList.map((list) => (
            <div key={list._id}>
              <RecommendProgram
                discountedPrice={list?.wp_program?.acf?.discounted_price}
                handleCardClick={() => handleProgramCardClick({ ...list })}
                partnerName={list?.wp_program?.acf?.partnered_by?.name}
                partnerStatus={list?.is_partnered_program}
                price={list?.wp_program?.acf?.price}
                programIcon={list?.programIcon}
                sponsorStatus={list?.sponsored_by}
                subText={list?.wp_program?.acf?.short_description}
                title={list?.wp_program?.title}
                week={list?.wp_program?.acf?.number_of_weeks}
              />
            </div>
          ))}
        </div>
      )}

      <ToastContainer
        hideProgressBar
        pauseOnHover
        autoClose={3000}
        className="f-12 fw-400"
        limit={1}
        position="bottom-center"
      />
    </React.Fragment>
  )
}
export default AllPrograms
