import { useMsal } from '@azure/msal-react'

import { isTokenExpiredFalse } from '../../utils/helper'
import SessionExpIcon from '../../assets/images/session-exp-icon.png'

function TokenExpiredModal({ isModalOpen, setTokenExpired }) {
  const { instance } = useMsal()

  const handleLogout = () => {
    document.dispatchEvent(isTokenExpiredFalse)
    setTokenExpired(false)
    localStorage.clear()
    sessionStorage.clear()
    instance.logoutRedirect()
  }

  return (
    <div className={`modal ${isModalOpen ? 'd-block' : 'd-none'}`}>
      <div className="modal-content text-center">
        <img alt="session exp" src={SessionExpIcon} />
        <h2 className="mb-4">Your session has expired</h2>
        <div>
          Oh No! Your session timed out after a long time of inactivity,
        </div>
        <div>Please click on the LOGIN.</div>
        <button type="button" onClick={() => handleLogout()}>
          LOGIN
        </button>
      </div>
    </div>
  )
}

export default TokenExpiredModal
