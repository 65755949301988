import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Left from '../assets/images/left.svg'

function TermsAndConditions () {

  const navigate = useNavigate()

  useEffect(() => {
    smartech('dispatch', 'Page Browse', {
      pageurl: window.location.href,
    })
  }, [])

  return (
    <React.Fragment>
      <div className="dashboard">
        <div className="vital-header d-flex">
          <div className="back-arrow-container d-flex justify-content-center align-items-center">
            <img
              alt="left arrow"
              className="back-arrow"
              role="presentation"
              src={Left}
              onClick={() =>
                setTimeout(() => {
                  navigate(-1)
                }, 300)
              }
            />
          </div>
          <div className="vital-text f-20 fw-700">Terms and Conditions</div>
        </div>
      </div>
      <div className="terms-condition-container f-18">
        <p className="">
          1. This website is intended to provide general information pertaining
          to the digital platform – the SVAAS platform – operated and maintained
          by Svaas Wellness Limited. The information on this website is meant
          for general consumption and to provide a broad understanding of the
          nature of activities pursued by Svaas Wellness Limited. The contents
          hereof are subject to frequent updates and hence, while all care is
          taken to provide accurate information, it must be borne in mind that
          the website continues to be under development. Please contact the
          Company for any specific queries. Nothing in this website shall amount
          to any representation or undertaking on any of the matters contained
          herein.
          <br />
        </p>
        <p className="">
          2. During the course of navigation of this site, we may collect and
          process the following information relating to you:
          <ol type="a">
            <li>
              Information (such as your name, date of birth, email address,
              postal address, telephone number, profile picture, area of
              specialization, details of your occupation/business, academic
              credentials and registration number) that you provide by
              completing forms on this website, including if you register as a
              user with us, subscribe to any of our services or those of a third
              party hosted on this website, upload or submit any material via
              this website or request information from us.
            </li>
            <li>
              In connection with your sign-in, your user name and password.
            </li>
            <li>
              Your activity on our website (automatically collected) when you
              use or log on to any of sites and the site you exit to including
              your browser details, your length of time of browsing, any details
              provided by you in any interactive exercise on our website.
            </li>
            <li>
              Communications you send to us, including queries, opinion,
              concerns or comments posted by you on our website or at time of
              registration for events or at forums on our site.
            </li>
            <li>
              Information from surveys that we may, from time to time, run on
              our website. Please note that you are under no obligation to
              provide any such information to us. However, if you should choose
              to not provide any information, it may impact your ability to
              access certain content or avail certain services through this
              website.
            </li>
          </ol>
          <br />
          3. By use/access of the contents of our web site, you authorise Svaas
          Wellness Limited to collect, store and use the personal information
          provided by you or collected from third parties across the world or
          publicly available professional information about you in an encrypted
          form to personalize the information we deliver to you, to complete any
          identity verification, to respond to any communications from you, to
          administer your use and access to this website and to any external
          links herein or to analyse or predict aspects concerning your
          interests, behaviour etc. You also hereby consent to our use of your
          personal information provided by you or accessed by us from your use
          of this website to :
          <ol type="a">
            <li>Improve our website and the services.</li>
            <li>Carry out research on user demographics. </li>
            <li>
              Audit the downloading of data or consumption of content available
              herein.
            </li>
            <li>
              To contact you or to send your information that may be useful or
              of interest to you or beneficial to you.
            </li>
            <li>
              For use in our legitimate business purposes, such as data
              collection and analysis, audits, developing new products, and
              enhancing and improving our website, products or services, or
            </li>
            <li>
              To develop and/or maintain relations and communications with you,
              to provide you with the status/details/other information about our
              products and services, to organize the approval, processing and
              signing of contracts, acts, invoices and other contractual
              documentation, to promote our new products, works and services, to
              confirm the high level of our products and services, to invite you
              to the meetings, events and organize them. You hereby agree to our
              sharing of your personal information to our third party vendors or
              service providers including our technical helpdesk or call centre
              operations and allow us to permit our affiliates or our select
              third parties to provide information on products or services that
              may be useful to you or of interest to you.
            </li>
          </ol>
          4. As a registered member, you give us and our partners’ permission to
          send you emails related to the new content uploaded on the website
          from time to time. If you are unwilling to receive these e-mail
          notifications, you can simply indicate your preference by clicking the
          Unsubscribe button in your email notification.
          <br />
          <br />
          5. We may disclose aggregate statistics about visitors to our website,
          subscription to or other viewing or downloading of contents herein in
          order to describe our services to prospective partners, investors,
          advertisers, sponsors and other reputable third parties and for other
          lawful purposes, but these will not include any personally
          identifiable information.
          <br />
          <br />
          6. If you believe that someone has used your personal details without
          your permission or attempted to impersonate you to gain access to our
          website, do let us know immediately by contacting us through this web
          site or by writing to us at{' '}
          <a href="mailto:dataprivacy@svaaswellness.com">
            dataprivacy@svaaswellness.com
          </a>
          <br />
          <br />
          7. Svaas Wellness Limited reserves the right to update, modify and
          discontinue the website at any time as it may deem fit.
          <br />
          <br />
          8. All transactions arising from or in relation to your use of this
          website shall be governed by Indian laws and any dispute arising
          therefrom shall be subject to the exclusive jurisdiction of the courts
          at Hyderabad, India.
          <br />
          <br />
          9. All content on this website shall be the sole property of Svaas
          Wellness Limited and protected by Indian copyright laws to the extent
          applicable. All legal right, title or interest to the trademark, logo,
          service marks, graphics used herein shall vest solely and exclusively
          in Svaas Wellness Limited. Your use of this website grants you no
          right or license to reproduce or otherwise use any content herein or
          the trademarks of Svaas or any third party.
          <br />
          <br />
          10. Svaas Wellness Limited disclaims all and any liability for any
          harm or damages resulting from your access or use of this website
          and/or any action taken or omitted to be taken upon reliance on any of
          its contents. In particular, Svaas Wellness Limited does not make any
          warranties or representations of any nature whatsoever, including the
          accuracy or completeness of the content placed on this website. Svaas
          Wellness Limited shall not be liable for any damages to your computer
          equipment or other property on account of your use, access, browsing.
          download of the Website or any content herein. If you are dissatisfied
          with the website, your sole remedy is to discontinue using the
          website.
          <br />
          <br />
          11. We use cookies to enhance your experience. By continuing to visit
          this website you agree to our use of cookies.
          <br />
          <br />
        </p>
      </div>
    </React.Fragment>
  )
}

export default TermsAndConditions
