import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import BgImage4 from '../../assets/images/bg-image-4.svg'
import BgImage5 from '../../assets/images/bg-image-5.svg'
import BgImage6 from '../../assets/images/bg-image-6.svg'
import CloseIcon from '../../assets/images/close.svg'

const UnsuccessComponent = memo(() => {
  const navigate = useNavigate()
  return (
    <div className="appointment-success d-flex flex-column">
      <div className="section">
        <img alt="bg-two" className="image1" src={BgImage5} />
        <img alt="bg-three" className="image2" src={BgImage6} />
        <img alt="bg-one" className="image3" src={BgImage4} />
        <h1 className="fw-bolder">Ooops!</h1>
        <img
          alt="close-icon"
          className="close-icon"
          role="presentation"
          src={CloseIcon}
          onClick={() => navigate('/vitals')}
        />
      </div>
      <div>
        <div className="text-center my-5">
          <p className="px-4 f-16 line-22">
            Your blood glucose levels are out of range. Connect with Health
            Coach to get personalized insights on managing blood glucose levels
            better
          </p>
        </div>

        <div className="d-flex ">
          <button className="d-flex fw-bold connect-hc-btn py-3" type="button">
            Connect with Health Coach
          </button>
        </div>
      </div>
    </div>
  )
})

export default UnsuccessComponent
