import Skeleton from 'react-loading-skeleton'
import { Card, CardContent, CountLoader } from './GfitComponent'

export function StepCountLoader() {
  return (
    <CountLoader>
      <div style={{ paddingTop: '18px' }}>
        <Skeleton height={50} width={116} />
        <p style={{ paddingTop: '6px' }}>
          {' '}
          <Skeleton height={10} width={100} />
        </p>
      </div>
    </CountLoader>
  )
}
export function ActivityCardLoader() {
  return (
    <Card margin="8px">
      <CardContent>
        <Skeleton circle height={30} width={30} />
        <Skeleton height={10} width={70} />
        <Skeleton height={10} width={40} />
      </CardContent>
    </Card>
  )
}

export function ChartLoader() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '21px' }}>
      <Skeleton height={178} width={311} />
    </div>
  )
}
