import PriceBadge from '../../../assets/images/price-badge-icon.svg'
import InsuranceIcon from '../../../assets/images/insurance-icon.svg'

function RecommendProgram({
  title,
  subText,
  price,
  discountedPrice,
  week,
  partnerStatus,
  handleCardClick,
}) {
 
  return (
    <div
      className="recommend-program d-flex"
      role="presentation"
      onClick={()=> handleCardClick()}
    >
      <div className="d-flex">
        <div className="recommend-program-text">
          <p className="f-18 fw-700">
            {title.replace('&#8211;', '-')}{' '}
            <span className="f-11 fw-600">({week} weeks)</span>
          </p>
          <p className="recommend-program-sub-text f-12 fw-400 pt-1">
            {subText}
          </p>
        </div>
      </div>
      <div className="recommend-program-footer">
       { partnerStatus ? <div/> : <div className="sponser-footer">
          <div className="footer-logo">
            <img alt="insurance" src={InsuranceIcon} />
          </div>
          <div className="footer-item">
            <p className="indigo-blue f-10">Sponsored by your organization</p>
          </div>
        </div>}
        <div className="price-footer d-flex align-items-center">
          <img alt="price-badge" src={PriceBadge} />
          <span className="f-14 fw-400 ">&#8377; {price}</span>{' '}
          <span className="f-14 fw-700">&#8377;{discountedPrice}</span>
        </div>
      </div>
    </div>
  )
}
export default RecommendProgram
