import { memo } from 'react'

const SuccessModal = memo(({ isSuccessModalOpen, children }) => (
  <div
    className={`success-modal-footer ${
      isSuccessModalOpen ? 'd-block' : 'd-none'
    }`}
  >
    <div className="modal-content vh-100">{children}</div>
  </div>
))

export default SuccessModal
