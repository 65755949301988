import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Left from '../assets/images/left.svg'

function PrivacyPolicy() {
  const navigate = useNavigate()

  useEffect(() => {
    smartech('dispatch', 'Page Browse', {
      pageurl: window.location.href,
    })
  }, [])

  return (
    <div className="dashboard">
      <div className="vital-header d-flex">
        <div className="back-arrow-container d-flex justify-content-center align-items-center">
          <img
            alt="left arrow"
            className="back-arrow"
            role="presentation"
            src={Left}
            onClick={() =>
              setTimeout(() => {
                navigate(-1)
              }, 300)
            }
          />
        </div>
        <div className="vital-text f-20 fw-700">Privacy Policy</div>
      </div>

      <div className="privacy-policy-container f-18">
        {/* <h1 className="privacy-heading text-center m-0 ">Privacy Policy</h1> */}

        <div className="content_container">
          <h2 className="f-32 ">Introduction</h2>
          <p>
            This Privacy Policy shall be applicable to all customers of SVAAS
            Wellness Pvt Ltd and users of{' '}
            <a
              href="https://www.svaaswellness.com/"
              rel="noreferrer"
              target="_blank"
            >
              www.svaaswellness.com
            </a>{' '}
            By accessing the Website or using any of our services, you have read
            and understood the terms and conditions of usage, which includes
            this Privacy Policy and agree to the terms.
          </p>
        </div>

        <div className="content_container">
          <h2 className=" f-32">Definitions</h2>
          <p>
            SVVAS Wellness or
            <span>&quot;Us&quot; or &quot;We&quot;</span>
            &#8211; refers to SVAAS Wellness Private Limited.
            <p>
              {' '}
              <span> &quot;Privacy Policy&quot; </span>
              &#8211; refers to this Policy (Notice).
            </p>
            <p>
              {' '}
              <span>
                &quot;You&quot; or &quot;Your&quot; or &quot;User&quot;(s)
              </span>
              &#8211; refers to the person accessing the Website in any
              capacity.
            </p>
            <span>&quot;Website&quot;</span>
            &#8211; refers to{' '}
            <a href="https://www.svaaswellness.com/">
              <u>www.svaaswellness.com</u>
            </a>
            and any subsequent pages under its sitemap.
            <br />
            <span>&quot;Services&quot;</span>
            &#8211; all services made available by SVAAS Wellness.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Personal Data</h2>
          <p>
            Data about or relating to a natural person who is directly or
            indirectly identifiable, having regard to any characteristic, trait,
            attribute or any other feature of the identity of such natural
            person, whether online or offline, or any combination of such
            features with any other information, and shall include any inference
            drawn from such data for the purpose of profiling.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Sensitive Personal Data</h2>
          <p>
            Means such personal data, which may, reveal, be related to, or
            constitute financial data, health data, official identifier, sex
            life, sexual orientation, biometric data, genetic data, transgender
            status, intersex status, caste or tribe, religious or political
            belief or affiliation. This also includes &quot;Sensitive Personal
            Data&quot; as defined under the Indian Information Technology Act,
            2008, and Sensitive Personal Data Information Technology Rules 2011.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Critical Personal Data</h2>
          <p>
            Means such personal data, which may, reveal, be related to, or
            constitute financial data, health data, official identifier, sex
            Personal data which may be notified by the Central Government as
            critical personal data.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Information Collection</h2>
          <p>
            We are committed to protecting your privacy and have taken
            reasonable steps to protect the confidentiality of the information
            you have provided us and its transmission through the World Wide
            Web. In the process of you using this website or availing of the
            existing or future services or facility provided by the website, you
            may be required to furnish information, including but not limited to
            Personal Information and/or Sensitive Personal Information. You are
            urged to keep the information current to ensure that the services
            and facility remain relevant and reach you. We may collect Personal
            Information such as Name, Phone Number, Address, Email ID, Age, etc.
            for provision of services. We may also collect Sensitive Personal
            Information or Health Information for provision of appropriate
            services.
          </p>
          <p>
            The information collected by us in one or more ways are mentioned
            below:
          </p>
          <ul>
            <li>
              Information you provide us &#8211; while using the Website and/or
              while availing the products and some of the services made
              available on the website.
            </li>
            <li>Information We get from your usage of website</li>
            <li>
              Information We get through cookies &#8211; We deploy cookies when
              you visit our website. Cookies are small electronic text files
              that contain information that a website uses to store User
              preferences and settings. You have the option to reset your
              browser to control cookies however, some of the Services offered
              by the website may not function properly if cookies are
              refused/reset/deleted.
            </li>
            <li>
              Information We may get from our affiliates or third parties
              &#8211; These could be from your employers, hospitals, medical
              practitioners, diagnostic service providers, Insurance Service
              Provider and/or commercially available sources such as public
              databases, data aggregators, social media, etc.
            </li>
          </ul>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Usage of Information</h2>
          <p>
            By using this website or our services, you authorize us to use your
            information for a variety of purposes including but not limited to
            the following:
          </p>
          <ul>
            <li>To provide You with insurance related services</li>
            <li>To provide services to all Users of the Website</li>
            <li>To personalize Your experience,</li>
            <li>To improve our Website,</li>
            <li>To improve our products and customer service,</li>
            <li>
              To process transactions, where requested, under Your User Id and
              Password,
            </li>
            <li>
              Send you information about us and / or our products and services,
              that is to contact you when required and/or
            </li>
            <li>
              To send periodic emails or otherwise for information related to
              our products and services,
            </li>
            <li>
              To administer a contest, promotion, survey or other site or
              business feature,
            </li>
            <li>To conduct statistical analysis,</li>
            <li>For sales and marketing activities.</li>
          </ul>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Sharing of Information</h2>
          <p>
            We undertake not to disclose, except as otherwise provided, the
            Personal Information and/or Sensitive Personal Information provided
            by you to any person, unless such action is necessary in the
            following cases:
          </p>
          <ul>
            <li>
              To meet requirements of any applicable law, regulation, legal
              process, governmental request, or customary practice directly or
              through our affiliates / banks / financial institutions / credit
              bureaus / agencies. Protect and defend SVAAS Wellness or its
              Affiliates rights, interests or property. The Company has defined
              responsibilities and processes to evaluate and respond to law
              enforcement or Government data requests. The submission is done in
              accordance with applicable rules and regulations.
            </li>
            <li>
              Enforce the terms and conditions of the products or services or
              Terms and Conditions.
            </li>
            <li>
              Act to protect the interests of SVAAS Wellness, its Affiliates, or
              its members, constituents or of other persons.
            </li>
            <li>
              For statistical analysis and verification or risk management.
            </li>
            <li>
              To enforce applicable terms and conditions, including
              investigation of potential violations.
            </li>
            <li>
              To detect, prevent, or otherwise address fraud, security or
              technical issues, analyse and manage other commercial risks.
            </li>
            <li>
              To protect against harm to the rights, property or safety of SVAAS
              Wellness, its customer or the public as required or permitted by
              law.
            </li>
            <li>
              To partners for the purpose of any financial product
              transaction/related transaction.
            </li>
            <li>
              To Third party service providers such as data cloud storage
              providers, call centres, payment gateways, banks to perform
              functions on our behalf. Examples include hosting data, delivering
              e-mail, and analysing data, providing marketing assistance,
              providing search results and providing customer service. The third
              party service providers are required to use appropriate security
              measures to protect the confidentiality and security of the
              Personal Information.
            </li>
          </ul>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Communication</h2>
          <p>
            SVAAS Wellness retains the right to communicate with you (via
            e-mail, postal service, courier, mobile messaging services, and
            telephone or social media extensions) when you have agreed to
            receive such communication or where operational or regulatory
            requirements require us to do so.
            <br />
            SVAAS Wellness further retains its right to communicate through
            third party vendors. You shall have the option to unsubscribe to
            receive such email communication.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Third-party Offers</h2>
          <p>
            SVAAS Wellness may allow third parties to offer subscription and/or
            registration-based Services through the Website, App or other
            platforms (viz. WhatsApp, Twitter etc.). SVAAS Wellness shall not be
            responsible for any actions or policies of such third parties and
            Users are requested to check the applicable privacy policy and
            acquaint themselves with the terms and conditions of usage of such
            service providers, when providing personally identifiable
            information.
            <br />
            SVAAS Wellness shall be not liable for any variance in the
            features/benefits offered by such third parties and shall not in any
            case assume warranty on the efficiency or efficacy of such offers.
            <br />
            <br />
            SVAAS Wellness shares certain personal data with third parties for
            activities including, but not limited to:
          </p>
          <ul>
            <li>Marketing purposes</li>
            <li>Document management/retention</li>
            <li>Cloud storage</li>
            <li>
              Cross-selling and up-selling of products of the SVASS Wellness or
              its subsidiaries.
            </li>
            <li>Fraud and cyber security related investigation purposes.</li>
          </ul>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Storage and Retention Information</h2>
          <p>
            The Company uses third party data centres and cloud services to
            securely store your data and to minimise data loss. The relevant
            security safeguards and contractual clauses as required by relevant
            Laws are adhered to while storing your data.
            <br />
            <br />
            We may retain your Personal Information and/or Sensitive Personal
            Information for long as it is necessary to fulfil the purpose for
            which such information was collected or for such a time as required
            to comply with regulations and applicable law.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Communication and Changes to Privacy Policy</h2>
          <p>
            SVAAS Wellness may amend or modify, this Privacy Policy including
            replacing this Privacy Policy with a new policy, at any time at the
            sole discretion of the Company. Revised Privacy Policy/amendments
            thereto shall be effective from the date indicated therein. Users
            are requested to periodically check the terms and conditions under
            this Privacy Policy from time to time. SVAAS Wellness shall not in
            any circumstance be held liable for such lapses on the part of the
            User.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Information Security</h2>
          <p>
            We take reasonable precautions to protect the website, information
            on the Website and our customers information from unauthorized
            access to or unauthorized alteration, disclosure or destruction of
            information We hold. In particular,
          </p>
          <ul>
            <li>We encrypt many of our Services using SSL certificates.</li>
            <li>
              We provide role-based access to Users who need to login to our
              systems/ applications.
            </li>
            <li>
              We provide password based authentication to users who login to our
              website.
            </li>
            <li>
              We review our information collection, storage and processing
              practices, to guard against unauthorized access to systems.
            </li>
            <li>
              We permit access to personally identifiable information to our
              employees, contractors and agents who are subject to strict
              contractual confidentiality obligations for data processing
              purposes.
            </li>
            <li>
              Conduct vulnerability assessments of our website and data
              processing systems periodically.
            </li>
          </ul>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Warranty Disclaimer</h2>
          <p>
            Information, content and materials provided on this Website are on
            an &quot;As Is&quot; basis and SVAAS Wellness does not make any
            express or implied representation or warranty regarding the
            accuracy, adequacy or completeness of the same.
          </p>
        </div>

        <div className="content_container">
          <h2 className="f-32 ">Contact Information</h2>
          <p>
            In case you require any further clarifications on the content of
            this policy or terms of use, please contact{' '}
            <a href="mailto:dataprivacy@svaaswellness.com">
              dataprivacy@svaaswellness.com
            </a>
          </p>
        </div>

        <div className="content_container">
          <h1 className="f-32 text-center">Appendix</h1>
          <p>
            Below table provides and indicates, what type of personal data We
            collect about you, how and for what purpose We process your personal
            data collected for a specified reason and with who all We share and
            further use such personal data We collect from you:
          </p>

          <table>
            <tbody>
              <tr>
                <td className="" data-align="center">
                  <b>What data we may collect</b>
                </td>
                <td className="" data-align="left">
                  <ol>
                    <li>
                      Your name, age, gender, phone number, e-mail address,
                      mailing address.
                    </li>
                    <li>Your height, weight.</li>
                    <li>
                      Your lifestyle, food preferences, medical conditions (if
                      any), health goals or other fitness regimes.
                    </li>
                    <li>
                      Your vitals including but not limited to blood Pressure,
                      blood glucose, heart rate, SpO2 etc.
                    </li>
                    <li>
                      Your diagnostic test results including but not limited to
                      Vitamin test, Complete Blood Count, Serum Creatinine,
                      HbA1c, Cholesterol etc.
                    </li>
                    <li>
                      Your activity data including but not limited to steps,
                      heart points, active minutes, exercise, cycling etc.
                    </li>
                    <li>
                      Your ethnicity, genetics, health or sexual orientation.
                    </li>
                    <li>Username or other relevant unique identifier.</li>
                    <li>
                      How you behave on the Website, (what pages you land on,
                      how much time you spend, etc.)
                    </li>
                    <li>
                      Credit card/debit card/other payment mode information to
                      facilitate payments for our Services.
                    </li>
                    <li>Your IP Address.</li>
                    <li>
                      Your location unless you deactivate location services in
                      the relevant section of the Website/App.
                    </li>
                    <li>
                      How you behave in the relevant product environment and use
                      the features.
                    </li>
                    <li>
                      What device you use to access the Website/App and its
                      details (model, operating system, etc.) and Cookies and
                      web beacon data.
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td className="" data-align="center">
                  <b>Who may have access to your data</b>
                </td>
                <td className="" data-align="left">
                  <ol>
                    <li>Your health coach.</li>
                    <li>
                      Anonymized data may be visible to a limited set of product
                      admins and data analysts at Myhealth.
                    </li>
                    <li>
                      Anonymized aggregated statistics may be made available to
                      your employer.
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td className="" data-align="center">
                  <b>How and why we may use your data</b>
                </td>
                <td className="" data-align="left">
                  <ol>
                    <li>
                      We collect this data in order to help you register for and
                      facilitate provision of our Services.
                    </li>
                    <li>
                      We use this data to show age appropriate content and
                      advertisements.
                    </li>
                    <li>
                      We use this data to track your nutrition, weight and
                      fitness regimes.
                    </li>
                    <li>
                      We use the data from the device you access our Website/App
                      to identify the login information of multiple users from
                      the same device.
                    </li>
                    <li>
                      We use this data to prepare a specific diet plan and
                      training regime for you.
                    </li>
                    <li>
                      We use this data to enable you to make payments for our
                      Services. We use a third-party service provider to manage
                      payment processing. This service provider is not permitted
                      to store, retain, or use information you provide except
                      for the sole purpose of payment processing on our behalf.
                    </li>
                    <li>
                      We use this data for processing your requests, enquiries
                      and complaints, customer services and related activities.
                    </li>
                    <li>
                      We use this data to communicate about existing or new
                      offers, content, advertisements, surveys, key policies or
                      other administrative information.
                    </li>
                    <li>
                      We also use this data to provide you with informative
                      features and services that are developed by us from time
                      to time.
                    </li>
                    <li>
                      We also use this data for providing, testing, improving,
                      or recommending the Services.
                    </li>
                    <li>
                      We also use this data for analytics and reviews to improve
                      the Services.
                    </li>
                    <li>
                      We also use this data to provide support to law
                      enforcement agencies or in connection with an
                      investigation on matters related to public safety, as
                      permitted by law or anti-fraud activities/systems.
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="my-4">
          I consent to being contacted for information, offers and promotions of
          related services from SVAAS Wellness.
        </p>
        <p className="pb-2 fw-700">
          Disclaimer: If and as you give us your consent, we may send you
          newsletters and emails to market other products and services we may
          provide.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
