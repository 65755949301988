import { memo } from 'react'
import LoaderGif from '../../assets/images/Loader.gif'

const Loader = memo(() => (
  <div className="wrapper">
    <img alt="animation" className="loading__anim" src={LoaderGif} />
  </div>
))

export default Loader
