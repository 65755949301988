import CalorieLogo from '../../assets/images/kcallogo.svg'
import ActiveLogo from '../../assets/images/ActiveMinlogo.svg'
import { Card, CardText, Logo } from './GfitComponent'

export function Calorie({ kcal }) {
  return (
    <Card margin="8px">
      <Logo
        alt="Calories"
        left="20px"
        position="relative"
        src={CalorieLogo}
        top="17px"
      />
      <CardText>
        {(kcal / 1000).toFixed(2)}
        <span style={{ color: '#47474A' }}>kcal</span>
      </CardText>
      <CardText color="#494E9D" size="12px" top="80px" weight="300">
        Calories
      </CardText>
    </Card>
  )
}
export function ActiveMin({ activeMin }) {
  return (
    <Card margin="8px">
      <Logo
        alt="Calories"
        left="20px"
        position="relative"
        src={ActiveLogo}
        top="17px"
      />
      <CardText>
        {activeMin}
        <span style={{ color: '#47474A' }}>min</span>
      </CardText>
      <CardText color="#494E9D" size="12px" top="80px" weight="300">
        Active min
      </CardText>
    </Card>
  )
}
