import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import Left from '../assets/images/left.svg'
import api from '../utils/api'
import FlareUpLogLoader from '../component/SkeletonLoader/FlarUpLogLoader'

function ReportSymptoms() {
  const navigate = useNavigate()
  const [flareUpList, setFlareUpList] = useState([])
  const [loading, setLoading] = useState(true)
  const [expandedCardId, setExpandedCardId] = useState()

  const getFlareUpList = async () => {
    try {
      const response = await api.get('/api/flareups-v2')
      if (response.status === 200) {
        setFlareUpList(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }
  const toggleExpand = (id) => {
    if (id === expandedCardId) {
      setExpandedCardId(null)
    } else {
      setExpandedCardId(id)
    }
  }
  useEffect(() => {
    getFlareUpList()
  }, [])

  return (
    <div>
      <ToastContainer
        hideProgressBar
        pauseOnHover
        autoClose={3000}
        className="f-12 fw-400"
        limit={1}
        position="bottom-center"
      />
      <div className="reports-page-header d-flex">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="back-arrow-report d-flex justify-content-center align-items-center">
              <img
                alt="left arrow"
                className="back-arrow"
                role="presentation"
                src={Left}
                onClick={() =>
                  setTimeout(() => {
                    navigate(-1)
                  }, 300)
                }
              />
            </div>
            <div className="reports-page-text">Reported Symptoms Logs</div>
          </div>
        </div>
      </div>
      <div className="flare-up-log-section">
        {loading && <FlareUpLogLoader />}
        {!loading && flareUpList?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-5">No logs found</div>
        ) : (
          flareUpList?.map((d) => (
            <div key={d?._id} className="flare-up-log-card">
              <div>
                <div className="log-item">
                  <dt>
                    <strong>What flared up ?</strong>
                  </dt>
                  <dd>{d?.what}</dd>
                </div>
                <div className="log-item">
                  <dt>
                    <strong>Reported Date:</strong>
                  </dt>
                  <dd>{moment(d?.created_date).format('DD-MM-YYYY')}</dd>
                </div>
                {d?._id === expandedCardId && (
                  <React.Fragment>
                    <div className="log-item">
                      <dt>
                        <strong>Symptoms:</strong>
                      </dt>
                      <dd>
                        {d?.symptoms &&
                          d.symptoms.map((s, index) => (
                            <span key={s}>
                              {s}
                              {d.symptoms.length - 1 === index ? '' : ', '}
                            </span>
                          ))}
                      </dd>
                    </div>
                    <div className="log-item">
                      <dt>
                        <strong>When it Happened:</strong>
                      </dt>
                      <dd>{d?.when}</dd>
                    </div>
                    <div className="log-item">
                      <dt>
                        <strong>Level of Severity:</strong>
                      </dt>
                      <dd>{d?.severity}</dd>
                    </div>
                    <div className="log-item">
                      <dt>
                        <strong>Diet Consumed:</strong>
                      </dt>
                      <dd>{d?.diet_consumed}</dd>
                    </div>
                    <div className="log-item">
                      <dt>
                        <strong>Reason:</strong>
                      </dt>
                      <dd>{d?.reason}</dd>
                    </div>
                    <div className="log-item">
                      <dt>
                        <strong>Comments:</strong>
                      </dt>
                      <dd>{d?.comments ? d?.comments : "No comments"}</dd>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div>
                <p
                  className="indigo-blue"
                  role="presentation"
                  onClick={() => toggleExpand(d?._id)}
                >
                  {d?._id === expandedCardId ? 'View less' : 'View more'}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default ReportSymptoms
