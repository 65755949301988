import Skeleton from 'react-loading-skeleton'

function AllProgramLoader() {
  return (
    <div
      className="loader-card d-flex"
      style={{
        borderRadius: '10px',
        background: 'white',
        padding: '15px',
        flexDirection: 'column',
        border: '1px solid #EDEDED',
        margin: '10px',
      }}
    >
      <div>
        <Skeleton width={150} />
        <Skeleton height={8} width={150} />
        <Skeleton height={8} width={150} />
      </div>
      <div>
        <Skeleton width={220} />
      </div>
    </div>
  )
}
export default AllProgramLoader
