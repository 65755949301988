import { memo} from 'react'
import { useNavigate } from 'react-router-dom'

import Chat from '../../assets/images/chat.svg'

const ChatButton = memo(({ messageCount }) => {
  const navigate = useNavigate()

  const handleSubmit = () => {
    navigate('/chat')
  }


  return (
    <button
      className="rounded-circle p-0 m-0 border-0 chat-button"
      type="button"
      onClick={handleSubmit}
    >
      <img alt="chat icon" className="p-0 m-0" src={Chat} />
      {messageCount > 0 && (
        <span className="line-14 notification-count f-10 fw-600 position-absolute d-flex align-items-center justify-content-center">
          {messageCount < 99 ? messageCount : '99+'}
        </span>
      )}
    </button>
  )
})

export default ChatButton
