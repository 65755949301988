export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const customEventFun = (value) =>
  new CustomEvent('loading', { detail: value })

export const tokenExpiredFun = (value) =>
  new CustomEvent('tokenExpired', { detail: value })

export const PROGRAM_INFORMATION_REQUESTED = 'Program Information Requested' 
export const BENEFIT_SECTION = 'title_icon_description_background_image'
export const POINTS_SECTION = 'points'
export const BENEFIT_OUTCOME_SECTION = 'icon_title_description'
export const QUESTIONS_ANSWER_SECTION = 'questions'