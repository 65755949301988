import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import SuccessImage from '../assets/images/success.svg'
import CloseIcon from '../assets/images/close.svg'

const SuccessScreen = memo((props) => {
  const navigate = useNavigate()
  return (
    <div className="text-center position-relative vh-100">
      {props?.reportPageProps ? null : (
        <img
          alt="close-icon"
          className="close-icon m-2"
          role="presentation"
          src={CloseIcon}
          onClick={() => {
            props?.setAnyBtnClick(false)
            props.setSuccessModalOpen(false)
            navigate('/vitals', {
              state: {
                weekNumber: props.weekNumber,
              },
            })
          }}
        />
      )}
      <div className="position-absolute top-50 start-50 translate-middle">
        <img alt="sucess " src={SuccessImage} />
        <p className="mt-5 indigo-blue fw-bold f-20 line-24 mb-0">
          {props?.reportPageProps ? 'Success! ' : 'Congratulation!'}
        </p>
        <p className="indigo-blue f-16 line-24 fw-400 mb-5">
          {props.showMessage}
        </p>
      </div>
      {props?.reportPageProps ? null : (
        <button
          className="w-90 connect-hc-btn py-3 fw-bold f-16 line-22 fixed-bottom"
          type="button"
          onClick={() => {
            props?.setAnyBtnClick(false)
            props.setSuccessModalOpen(false)
            navigate('/callback')
          }}
        >
          Go Back to My Health
        </button>
      )}
    </div>
  )
})

export default SuccessScreen
