import React, { useEffect, useState } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import getMonth from 'date-fns/getMonth'
import api from '../../utils/api'
import RightArrow from '../../assets/images/right-arrow.svg'
import LeftIcon from '../../assets/images/left.svg'
import RightIcon from '../../assets/images/right.svg'
import { months } from '../../utils/constant'

const today = new Date()
const time = `${today.getHours()}:00:00`
const minDate =
  time < '18:00:00' ? today : new Date().setDate(new Date().getDate() + 1)
const maxDate =
  time < '18:00:00'
    ? new Date().setDate(new Date().getDate() + 15)
    : new Date().setDate(new Date().getDate() + 16)

function RaiseCallRequest({
  error,
  showDatePicker,
  showTimeSlots,
  showUserMobileSection,
  handleScheduleCall,
  mobile,
  setError,
  setMobile,
  setShowDatePicker,
  setShowTimeSlots,
  disabled,
  setShowUserMobileSection,
  taskSpeciality
}) {
  const [selectedDate, setSelectedDate] = useState(minDate)

  const [isTimeslotsLoading, setIsTimeslotsLoading] = useState(false)
  const [timeslots, setTimeslots] = useState([])
  const [selectedTimeslot, setSelectedTimeslot] = useState('')
  const fetchTimeslots = () => {
    setIsTimeslotsLoading(true)

    api.get('api/timeslots').then((res) => {
      if (res?.status === 200) {
        setTimeslots(res?.data?.data)
        setIsTimeslotsLoading(false)
        if (
          moment(selectedDate).format('l') === moment(today).format('l') &&
          time < '18:00:00'
        ) {
          const findIndexOfCurrentTimeSlot = res?.data?.data.findIndex(
            (itm) => itm.start_time === time
          )
          setSelectedTimeslot(
            res?.data?.data[findIndexOfCurrentTimeSlot + 1]?._id
          )
        } else if (
          moment(selectedDate).format('l') === moment(today).format('l') &&
          time >= '18:00:00'
        ) {
          setSelectedDate(new Date().setDate(new Date().getDate() + 1))
        } else {
          setSelectedTimeslot(res?.data?.data[0]._id)
        }
      }
    })
  }

  useEffect(() => {
    fetchTimeslots()
  }, [])
  return (
    <div>
      {!isTimeslotsLoading &&
      !showDatePicker &&
      !showTimeSlots &&
      !showUserMobileSection ? (
        <React.Fragment>
          <div className="mb-4">
            <p className="mb-0 f-16 line-22 charcoal-grey fw-600">
              Schedule call with
            </p>
            <p className="mb-0 f-20 line-28 fw-bold">
              {taskSpeciality || 'Health Coach'}
            </p>
          </div>
          <div>
            <p className="fw-bold f-14 line-16 dark-gray">
              Select Preferred Timeslot
            </p>
            <div className="time-slot">
              <button
                className="time-slot-btn f-16 line-22"
                type="button"
                onClick={() => setShowDatePicker(true)}
              >
                {moment(selectedDate).format('DD MMMM YYYY')}
              </button>
              <button
                className="time-slot-btn f-16 line-22"
                type="button"
                onClick={() => {
                  setShowTimeSlots(true)
                  setShowDatePicker(false)
                }}
              >
                {timeslots && timeslots.length > 0
                  ? timeslots.find((itm) => itm._id === selectedTimeslot)?.label
                  : selectedTimeslot}
              </button>
            </div>
          </div>
          <button
            className="mt-4 confirm-appointment-btn line-18 f-16 fw-bold width-100"
            disabled={disabled}
            type="button"
            onClick={() => {
              setShowUserMobileSection(true)
            }}
          >
            Confirm Appointment
          </button>
        </React.Fragment>
      ) : (
        ''
      )}
      {showDatePicker && (
        <React.Fragment>
          <DatePicker
            inline
            className="reschedule-date-picker"
            formatWeekDay={(nameOfDay) => nameOfDay.toString().substr(0, 1)}
            maxDate={maxDate}
            minDate={minDate}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div className="d-flex justify-content-between mb-4">
                <div className="fw-bold f-16 line-18">Select Date</div>
                <div className="fw-bold f-14 line-16 d-flex align-items-center">
                  <img
                    alt="left arrow"
                    className="me-4"
                    role="presentation"
                    src={LeftIcon}
                    onClick={decreaseMonth}
                  />
                  {months[getMonth(date)]}
                  <img
                    alt="right arrow"
                    className="ms-4"
                    role="presentation"
                    src={RightIcon}
                    onClick={increaseMonth}
                  />
                </div>
              </div>
            )}
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
          <div className="d-flex justify-content-between select-date-next-btn">
            <p className="mb-0 fw-bold f-14 line-18">
              {moment(selectedDate).format('ddd, D MMM')}
            </p>
            <p
              className="mb-0 indigo-blue fw-bold f-16 line-18"
              role="presentation"
              onClick={() => {
                setShowDatePicker(false)
                setShowTimeSlots(true)
              }}
            >
              Next <img alt="right arrow" src={RightArrow} />
            </p>
          </div>
        </React.Fragment>
      )}
      {showTimeSlots && (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0 fw-600 f-20 line-24">
                {moment(selectedDate).format('dddd')}
              </p>
              <p className="mb-0 fw-600 f-16 line-24">
                {moment(selectedDate).format('DD MMM YYYY')}
              </p>
            </div>
            <div
              className="indigo-blue fw-bold f-16 line-16"
              role="presentation"
              onClick={() => {
                setShowTimeSlots(false)
                setShowDatePicker(true)
              }}
            >
              Change
            </div>
          </div>
          <div className="mt-38">
            <h6 className="fw-600 f-20 line-24 mb-4">Select time</h6>
            <ul className="time-span p-0 mb-5 text-center">
              {timeslots &&
                timeslots.length > 0 &&
                timeslots.map((itm) => (
                  <li
                    key={itm?._id}
                    className={`fw-bold f-14 line-18 ${
                      itm._id === selectedTimeslot ? 'active' : ''
                    } ${
                      moment(selectedDate).format('l') ===
                        moment(today).format('l') && time + 1 > itm.start_time
                        ? 'disable'
                        : ''
                    }`}
                    role="presentation"
                    onClick={() => {
                      if (
                        moment(selectedDate).format('l') ===
                          moment(today).format('l') &&
                        time + 1 < itm.start_time
                      ) {
                        setSelectedTimeslot(itm?._id)
                      } else if (
                        moment(selectedDate).format('l') !==
                        moment(today).format('l')
                      ) {
                        setSelectedTimeslot(itm?._id)
                      }
                    }}
                  >
                    {itm?.label}
                  </li>
                ))}
            </ul>
          </div>
          <button
            className="confirm-appointment-btn line-18 f-16 fw-bold"
            type="button"
            onClick={() => {
              setShowTimeSlots(false)
            }}
          >
            Confirm
          </button>
        </React.Fragment>
      )}
      {showUserMobileSection && (
        <React.Fragment>
          <p className="mb-0 fw-bold f-18 line-24 text-center mb-4">
            Confirm your mobile number
          </p>
          <p className="mb-0 f-16 line-22 text-center px-4 mb-4">
            You will receive a call on this number from the{' '}
            {taskSpeciality || 'Health Coach'}
          </p>
          <div className="input-group px-5">
            <span className="input-group-text" id="basic-addon1">
              +91
            </span>
            <input
              className="form-control"
              type="number"
              value={mobile.replace('+91', '')}
              onChange={(e) => {
                if (e.target.value.length === 11) return false
                setMobile(e.target.value)
                setError('')
              }}
            />
            <div className="invalid-feedback d-block f-12 line-16 text-center error-color mt-3">
              {error}
            </div>
          </div>
          <button
            className={`${
              mobile.replace('+91', '')?.length === 10
                ? 'confirm-appointment-btn'
                : 'confirm-btn-disable'
            } line-18 f-16 fw-bold mt-5`}
            disabled={mobile.replace('+91', '')?.length !== 10 || disabled}
            style={{ width: '100%' }}
            type="button"
            onClick={(e) =>
              handleScheduleCall(e, selectedDate, selectedTimeslot)
            }
          >
            Confirm Mobile Number
          </button>
        </React.Fragment>
      )}
    </div>
  )
}

export default RaiseCallRequest
