import React from 'react'
import ReactDOM from 'react-dom'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { Provider } from 'react-redux'

import Routes from './routes'
import { store } from './store/index.ts'
import { msalConfig } from './utils/authConfig'
import { loadingTrue, loadingFalse } from './utils/helper'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './libcss'

smartech('create', process.env.REACT_APP_NETCORE_USER_KEY, 'tin')
smartech('register', process.env.REACT_APP_NETCORE_SITEID)
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
/* eslint-disable import/prefer-default-export */
export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
  const getNumber = accounts[0]?.idTokenClaims['signInNames.phoneNumber']
  smartech('identify', getNumber.slice('3'))
}

msalInstance.addEventCallback((event) => {
  switch (event.eventType) {
    case EventType.INITIALIZE_START:
      document.dispatchEvent(loadingTrue)
      break
    case EventType.INITIALIZE_END:
      document.dispatchEvent(loadingTrue)
      break
    case EventType.HANDLE_REDIRECT_START:
      document.dispatchEvent(loadingTrue)
      break
    case EventType.ACQUIRE_TOKEN_START:
      document.dispatchEvent(loadingTrue)
      break
    case EventType.ACQUIRE_TOKEN_SUCCESS:
      document.dispatchEvent(loadingFalse)
      break
    case EventType.LOGIN_START:
      document.dispatchEvent(loadingTrue)
      break
    case EventType.LOGIN_SUCCESS:
      document.dispatchEvent(loadingFalse)
      break
    case EventType.HANDLE_REDIRECT_END:
      document.dispatchEvent(loadingFalse)
      break
    default:
      break
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    document.dispatchEvent(loadingTrue)
    const { payload } = event
    const { account } = payload
    msalInstance.setActiveAccount(account)
    const getNumber = account?.idTokenClaims['signInNames.phoneNumber']
    smartech('identify', getNumber.slice('3'))
    document.dispatchEvent(loadingFalse)
  }
})

if (module.hot) {
  module.hot.accept()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes msalInstance={msalInstance} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
