import gfitApi from '../../utils/gfitApi'

const stepTrackerData = async (startTime, endTime, activity_id) => {
  const gfitData = await gfitApi
    .get(
      `api/live-gfit?startTime='${startTime}'&endTime='${endTime}'&duration=3600000` +
        `&activityId=${activity_id}`
    )
    .then((res) => res)
    .catch((err) => err)
  return gfitData
}

export default stepTrackerData
