import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import SuccessScreen from './success-screen'
import SuccessModal from '../component/CommonComponent/SuccessModal'
import Left from '../assets/images/left.svg'
import api from '../utils/api'

function Report() {
  const navigate = useNavigate()
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
  const [showMessage, setShowMessage] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)
  const [flareUpData, setFlareUpData] = useState({
    severity: 0,
    symptoms: [],
  })

  const handleViewLog = () => {
    navigate('/report-symptoms')
  }

  const handleInputChange = (event) => {
    setFlareUpData({
      ...flareUpData,
      [event.target.name]: event.target.value,
    })
  }

  const handleRangeChange = (event) => {
    setFlareUpData({
      ...flareUpData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSelectChange = (selectedOption) => {
    const symptomsArray = []
    selectedOption?.map((option) => symptomsArray.push(option?.value))
    setFlareUpData({
      ...flareUpData,
      symptoms: symptomsArray,
    })
  }

  const handleReasonChange = (selectedOption) => {
    setFlareUpData({
      ...flareUpData,
      reason: selectedOption?.label,
    })
  }
  const submitFlareUp = () => {
    setButtonDisable(true)
    api
      .post('api/flareups-v2', flareUpData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          setSuccessModalOpen(true)
          setShowMessage('Your Report Has been Submitted successfully')
          setTimeout(() => {
            setSuccessModalOpen(false)
            navigate('/callback')
          }, 3000)
        }
      })
      .catch((err) => {
        toast.error(err?.message)
      })
  }

  const flaredUpValid = () => {
    if (
      flareUpData.what &&
      flareUpData.when &&
      flareUpData.severity &&
      flareUpData.diet_consumed &&
      flareUpData.reason &&
      flareUpData.symptoms.length > 0
    ) {
      return true
    }
  }

  const options = [
    { value: 'fever', label: 'Fever' },
    { value: 'cold', label: 'Cold' },
    { value: 'cough', label: 'Cough' },
  ]
  const reasonOptions = [
    { value: 'diet', label: 'Diet' },
    { value: 'stress', label: 'Stress' },
    { value: 'medication', label: 'Medication' },
    { value: 'exhaustion', label: 'Exhaustion' },
    { value: 'lack of sleep', label: 'Lack of Sleep' },
    { value: 'physical injury', label: 'Physical Injury' },
  ]
  return (
    <div>
      <ToastContainer
        hideProgressBar
        pauseOnHover
        autoClose={3000}
        className="f-12 fw-400"
        limit={1}
        position="bottom-center"
      />
      <div className="reports-page-header d-flex">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="back-arrow-report d-flex justify-content-center align-items-center">
              <img
                alt="left arrow"
                className="back-arrow"
                role="presentation"
                src={Left}
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="reports-page-text">Report Symptoms</div>
          </div>
          <div
            className="view-log-text"
            role="presentation"
            onClick={handleViewLog}
          >
            View Log
          </div>
        </div>
      </div>
      <div className="flare-up-form-section">
        <div>
          <p>What flared up? </p>
          <input
            className="flare-up-input"
            name="what"
            placeholder="Enter what flared up"
            type="input"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Symptoms noticed</p>
          <Select
            isMulti
            className="symptom-select"
            options={options}
            placeholder="Select symptoms" // Prevent showing selected options inside the select box
            onChange={handleSelectChange}
          />
        </div>
        <div>
          <p>When did this flare up occur?</p>
          <input
            className="flare-up-input"
            max={new Date().toISOString().split("T")[0]}
            name="when"
            type="date"
            onChange={handleInputChange}
            onKeyDown={(event) => event.preventDefault()}
          />
        </div>
        <div>
          <p>On a scale of 1 to 10 what is the severity of the flare up?</p>
          <div className="slider-range">
            <input
              className="flare-up-input"
              max="10"
              min="1"
              name="severity"
              style={{ height: '0px' }}
              type="range"
              value={flareUpData.severity}
              onChange={handleRangeChange}
            />
            <span>{flareUpData?.severity}</span>
          </div>
        </div>
        <div>
          <p className="flare-up-text">What is the diet consumed?</p>
          <input
            className="flare-up-input"
            name="diet_consumed"
            placeholder="Enter the Diet"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>What do you suspect is the reason for this flareup?</p>
          <Select
            className="symptom-select"
            options={reasonOptions}
            placeholder="Select symptoms" // Prevent showing selected options inside the select box
            onChange={handleReasonChange}
          />
        </div>
        <div>
          <p className="flare-up-text">Additional comments</p>
          <textarea
            className="flare-up-input"
            name="comments"
            style={{ height: '70px' }}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flare-btn-section">
        <button
          className={
            !flaredUpValid()
              ? 'flare-up-report-btn-disable'
              : 'flare-up-report-btn'
          }
          disabled={!flaredUpValid() || buttonDisable}
          type="button"
          onClick={() => {
            submitFlareUp()
          }}
        >
          Report
        </button>
      </div>
      {isSuccessModalOpen && (
        <SuccessModal isSuccessModalOpen={isSuccessModalOpen}>
          <div>
            <SuccessScreen
              reportPageProps={null}
              setSuccessModalOpen={setSuccessModalOpen}
              showMessage={showMessage}
            />
          </div>
        </SuccessModal>
      )}
    </div>
  )
}

export default Report
