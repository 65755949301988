import { InteractionRequiredAuthError } from '@azure/msal-browser'
import axios from 'axios'
import { msalInstance } from '..'
import { loginRequest } from './authConfig'

import { wordPressUrl, isTokenExpiredTrue, loadingFalse } from './helper'

const wordPressAPi = axios.create({
  baseURL: wordPressUrl,
})

const refrestAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts()
  try {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    return token.accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenRedirect(loginRequest)
        .then((response) => response.accessToken)
        .catch((err) => err)
    }
  }
}

wordPressAPi.interceptors.request.use(
  async (config) => {
    const token = await refrestAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

wordPressAPi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.name &&
      error.response.data.message.name === 'TokenExpiredError'
    ) {
      document.dispatchEvent(loadingFalse)
      document.dispatchEvent(isTokenExpiredTrue)
    }
    return Promise.reject(error)
  }
)

export default wordPressAPi
