import { memo } from 'react'

const MiniCard = memo(({ heading_text, sub_text, icon, onClick }) => (
  <div className="mini-card" role="presentation" onClick={onClick}>
    <div>
      {/* <img src={bg_icon_1} className="bg-image-2 py-2" alt="bg-image" />
          <img src={bg_icon_2} className="bg-image-1 py-2" alt="bg-image" /> */}
      <img alt="icon" className="z-index-1 px-2" src={icon} />
    </div>
    <div className="px-2">
      <p className="mb-1 f-11 line-15">{heading_text}</p>
      <p className="mb-0 mt-1 text-dark fw-bolder f-16 line-22 d-flex align-items-center">
        {sub_text && sub_text < 10 ? `0${sub_text}` : sub_text}{' '}
      </p>
    </div>
  </div>
))

export default MiniCard
