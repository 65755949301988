import { memo } from 'react'
import ConfirmEmail from '../../assets/images/email-confirm.svg'
import PreferDateTime from '../../assets/images/prefer-date-time.svg'
import CallStatus from '../../assets/images/call-status.svg'
import PreferDateTimeConfirm from '../../assets/images/prefer-date-time-confirm.svg'

const StatusStepper = memo(({ step }) => (
  <div className="stepper-box">
    <div className="status-progress">
      <div className={`progress-bar ${step === 10 ? 'initial' : 'final'}`} />
    </div>
    <div className="status-stepper">
      <div className="status">
        <img alt="email" className="status-email" src={ConfirmEmail} />
      </div>
      <div className="status">
        {step === 10 ? (
          <img alt="date-time" src={PreferDateTime} />
        ) : (
          <img alt="date-time" src={PreferDateTimeConfirm} />
        )}
      </div>
      <div className="status">
        <img alt="call-status" className="status-call" src={CallStatus} />
      </div>
    </div>
  </div>
))
export default StatusStepper
