import moment from 'moment';
import { customEventFun, tokenExpiredFun } from './constant'

export const url = process.env.REACT_APP_API_ENDPOINT
export const chatUrl = process.env.REACT_APP_API_CHAT_ENDPOINT
export const gFitUrl = process.env.REACT_APP_API_GFIT_ENDPOINT
export const azureRedirectUrl = process.env.REACT_APP_AZURE_REDIRECT_URL
export const azureUserFlow = process.env.REACT_APP_AZURE_USER_FLOW
export const azureAuthorityUrl = process.env.REACT_APP_AZURE_AUTHORITY_URL
export const azureAuthorityDomainUrl =
  process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN_URL
export const azureClientId = process.env.REACT_APP_AZURE_CLIENTID
export const isLoginVisible = process.env.REACT_APP_IS_LOGIN_VISIBLE
export const redirectUrl = process.env.REACT_APP_MY_HEALTH_REDIRECT_URL
export const wordPressUrl = process.env.REACT_APP_MDM_API_URL
export const stressSurveyLink = process.env.REACT_APP_STRESS_TYPEFORM_URL
export const lifeStyleSurveyLink = process.env.REACT_APP_LIFESTYLE_TYPEFORM_URL

export const getLocalStorageItem = (key) => localStorage.getItem(key)

export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value)

export const removeLocalStorageItem = (key) => localStorage.removeItem(key)

export const isUpperCase = (value) => /[A-Z]/.test(value)

export const isLowerCase = (value) => /[a-z]/.test(value)

export const isContainNumber = (value) => /\d/.test(value)

export const isSpecialChar = (value) =>
  /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value)

export const isEmpty = (obj) => Object.keys(obj).length === 0

export const arrayUniqueByKey = (key, array) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
]

export const loadingFalse = customEventFun(false)
export const loadingTrue = customEventFun(true)
export const isTokenExpiredFalse = tokenExpiredFun(false)
export const isTokenExpiredTrue = tokenExpiredFun(true)

export const convertInto12h = (time) => {
  const tempHours = Number(time.substr(0, 2))
  let hours = tempHours > 12 ? tempHours - 12 : tempHours
  const ampm = tempHours >= 12 ? 'PM' : 'AM'
  hours = hours < 10 ? `0${hours}` : hours
  const tempMinute = Number(time.substr(3, 5))
  const minutes = tempMinute < 10 ? `0${tempMinute}` : tempMinute
  const newTime = `${hours}:${minutes} ${ampm}`
  return newTime
}

export const DisplayCurrentTime = () => {
  const date = new Date()
  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
  hours = hours < 10 ? `0${hours}` : hours
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const time = `${hours}:${minutes} ${ampm}`
  return time
}

export const getProgramCurrentWeek = (startDate) => {
  const today = moment();
  const deltaDays = today.diff(startDate, 'days');
  const currentWeek = Math.floor(deltaDays / 7) + 1;
  
  const startOfCurrentWeek = moment(startDate).add((currentWeek - 1) * 7, 'days');
  const endOfCurrentWeek = startOfCurrentWeek.clone().add(6, 'days');

  return [
    currentWeek,
    startOfCurrentWeek.format('Do MMM'),
    endOfCurrentWeek.format('Do MMM')
  ];
}

export const getWeekDays = (start_date, week_number) => {
  const startDate = new Date(start_date);

  const startingDate = new Date(startDate.getTime() + (week_number - 1) * 7 * 24 * 60 * 60 * 1000);

  const weekDays = [];
  for (let i = 0; i < 7; i += 1) {
    const day = new Date(startingDate.getTime() + i * 24 * 60 * 60 * 1000);
    weekDays.push(moment(day).format('Do MMM'));
  }

  return [weekDays[0], weekDays[6]];  
}

export const mappedActivityBucketsAndActivities = (activities) => {
    
      const unique = new Map();
      activities?.forEach((item) => {
      const activities_task_id = item?.activities_task_id;
      const activity_date = item?.program_activity?.activity_date;
// If the unique map doesn't have the activities_task_id or the current item's date is later than the stored date
      if (!unique.has(activities_task_id) || activity_date > unique.get(activities_task_id)?.program_activity.activity_date) {
        unique.set(activities_task_id, item);
      }
    });

     const uniqueTasksArray = Array.from(unique.values());
     return uniqueTasksArray;
}

export const mappedCustomTasks = (customTasks) => {
  if(customTasks.length > 0) {
    return customTasks.reduce((acc, curr) => {
      const existingItem = acc.find(item => item._id === curr._id);

      if(!existingItem) {
        acc.push({ _id: curr._id, name: curr.name, route: curr.route, start_date: curr.start_date, end_date: curr.end_date, dod_rule: curr.dod_rule, dod_completed: curr.dod_completed, activities_task_id: curr.activities_task_id, activities: [curr.program_activity], latest_record: curr.program_activity?.activity_date });
      } else {
        existingItem.activities.push(curr.program_activity);

        if(curr.program_activity.activity_date > existingItem.latest_record) {
          existingItem.latest_record = curr.program_activity.activity_date
        }
      }

      return acc;
    }, []);
  }

  return [];
}