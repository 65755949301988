import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Left from '../../assets/images/left.svg'
import RunLogo from '../../assets/images/gfit-run-logo.svg'
import DailyCharts from './step-tracker-charts/DailyCharts'
import {
  GfitRunLogo,
  ProgressSection,
  StepText,
  StepContent,
  StepSection,
} from './GfitComponent'
import { DateFetcher } from './DateSelector'
import { StepCountLoader } from './StepTrackerLoader'

function Gfit() {
  const navigate = useNavigate()
  const [stepCount, setStepCount] = useState('')
  const [lastUpdateDate, setLastUpdateDate] = useState('')
  const [loading, setLoading] = useState(true)
  const [background, setBackGround] = useState('')
  const { activity_id } = useLocation().state

  useEffect(() => {
    const dateValues = DateFetcher()
    const { currentDate } = dateValues
    setLastUpdateDate(moment(currentDate).format('DD/MM/YYYY'))
    window.scroll(0, 0)
    smartech('dispatch', 'Page Browse', {
      pageurl: window.location.href,
    })
  }, [])

  const handleStepCount = (steps, response) => {
    if (response === 200) {
      const stepInKm = steps * 0.000762
      setStepCount(Math.round(stepInKm * 10) / 10)
      setLoading(false)
      setBackGround('#494E9D')
    } else if (response !== 200) {
      setLoading(false)
      setBackGround('#494E9D')
    }
  }
  return (
    <React.Fragment>
      <div className="gFit-header d-flex">
        <div
          className="back-arrow-container d-flex justify-content-center align-items-center"
          style={{ top: '47px' }}
        >
          <img
            alt="left arrow"
            className="back-arrow"
            role="presentation"
            src={Left}
            onClick={() =>
              setTimeout(() => {
                navigate('/callback')
              }, 300)
            }
          />
        </div>
        <div className="gFit-text f-20 fw-700">Steps</div>
        <div className="gFit-update-text f-14 fw-600">
          Last updated:
          {lastUpdateDate}
        </div>
      </div>

      <StepSection>
        <div className="d-flex justify-content-center w-100">
          {loading ? (
            <StepCountLoader />
          ) : (
            <StepContent width="322px">
              <StepText font-size="44px" lineHeight="65px">
                {stepCount || (stepCount === 0 ? 0 : '--')}{' '}
                <span className="step-unit f-32 position-relative line-43">
                  Km
                </span>
                <br />
                <span className="f-14 fw-300 d-flex line-16 ps-1">
                  Distance Covered
                </span>
              </StepText>
            </StepContent>
          )}
          <div className="gfit-logo">
            <GfitRunLogo src={RunLogo} />
          </div>
        </div>
      </StepSection>
      <ProgressSection backGroundColor={background}>
        <DailyCharts
          activity_id={activity_id}
          handleStepCount={handleStepCount}
        />
      </ProgressSection>
    </React.Fragment>
  )
}

export default Gfit
