import { memo } from 'react'

import moment from 'moment'
import GreenTick from '../../assets/images/green-tick.svg'
import EllipseGreen from '../../assets/images/ellipse-green.svg'
import Calendar from '../../assets/images/CalendarIcon.svg'

const CallbackRequestCard = memo(({ data, setIsModalOpen, setSelectedCallRequestForReschedule }) => (
  <div className="callback-request-card row py-3 mx-3 px-1 position-relative">
      <div className="col-9 d-flex justify-content-between align-items-center">
        <img
          alt="ellipse-green"
          className="position-absolute start-0"
          src={EllipseGreen}
        />
        <div>
          <img alt="green-tick" src={GreenTick} />
        </div>
        <div>
          <p className="f-13 line-16 indigo-blue fw-300 call-text upcoming-header">
            Call booked
          </p>
          <p className="m-0 f-14 line-16 fw-400">
            with{' '}
            <b className="f-16 line-16 fw-bold">{data?.healthcoach_name}</b>
          </p>
          <p className="m-0 f-11 line-16 dark-gray fw-400 d-flex">
            <img alt="calendar" src={Calendar} width={16} />
            &nbsp;
            {moment(data?.consultation_date).format('MMM DD YYYY')}
            ,&nbsp;
            <span>
              {data?.timeslot.substr(0, 2) < 10
                ? `${data?.timeslot.substr(1, 5)}${data.timeslot.substr(
                    15,
                    16
                  )}`
                : `${data?.timeslot.substr(0, 6)}${
                    data.timeslot === '11:00 to 12:00 PM'
                      ? 'AM'
                      : `${data.timeslot.substr(15, 16)}`
                  }`}
            </span>
          </p>
        </div>
        <div className="right-border" />
      </div>
      <div
        className="col-3 p-0 f-12 line-16 fw-700 indigo-blue m-auto"
        role="presentation"
        onClick={() => {
          setIsModalOpen(true)
          setSelectedCallRequestForReschedule(data)
          smartech('dispatch', 'Page Browse', {
            pageurl: `${window.location.href}/rescheduleCall/Initiated`,
          })
        }}
      >
        Reschedule
      </div>
    </div>
))

export default CallbackRequestCard
