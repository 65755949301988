import moment from 'moment'
import { useState } from 'react'
import Left from '../../assets/images/left.svg'
import Right from '../../assets/images/right.svg'
import { Arrow, DateContainer, DateTextContainer } from './GfitComponent'
import stepTrackerData from './stepTracker'

export function DateSelector({
  activity_id,
  date,
  month,
  handleStepData,
  handleLoading,
}) {
  const [showDate, setShowDate] = useState('')
  const [dateSubtract, setDateSubtract] = useState(1)
  const [displayDate, setDisplayDate] = useState(true)
  const [disable, setDisable] = useState(true)
  const [dateAdder, setDateAdder] = useState(-1)

  const handleReverseDate = () => {
    handleLoading()
    setDisable(false)
    if (date) {
      const newDate = new Date()
      setDisplayDate(false)
      setDateSubtract(dateSubtract + 1)
      setDateAdder(dateAdder + 1)
      const previousDate = newDate.setDate(
        newDate.getDate() - `${dateSubtract}`
      )
      setShowDate(moment(previousDate).format('D MMMM'))

      const gfitStepData = stepTrackerData(
        new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate()
        ).toUTCString(),
        new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate() + 1
        ).toUTCString(),
        activity_id
      )

      gfitStepData.then((res) => {
        handleStepData(res)
      })
    }
  }
  const handleForwardDate = () => {
    if (date) {
      if (dateAdder !== -1) {
        handleLoading()
        setDateSubtract(dateSubtract - 1)
        setDateAdder(dateAdder - 1)
        const newDate = new Date()
        const forwardDate = newDate.setDate(newDate.getDate() - `${dateAdder}`)
        setShowDate(moment(forwardDate).format('D MMMM'))

        const gfitStepData = stepTrackerData(
          new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate()
          ).toUTCString(),
          new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 1
          ).toUTCString(),
          activity_id
        )

        gfitStepData.then((res) => {
          handleStepData(res)
        })
      } else {
        setDisable(true)
      }
    }
  }
  return (
    <DateContainer>
      <Arrow id="reverse" onClick={handleReverseDate}>
        <img alt="left" src={Left} />
      </Arrow>
      <DateTextContainer>
        {date && displayDate ? (
          <p>
            {date} {month}
          </p>
        ) : (
          <p>{showDate}</p>
        )}
      </DateTextContainer>
      <Arrow disabled={disable} id="forward" onClick={handleForwardDate}>
        <img alt="right" src={Right} />
      </Arrow>
    </DateContainer>
  )
}

export const DateFetcher = () => {
  const currentDate = new Date()
  const newDate = currentDate.getDate()
  const monthName = new Date().toLocaleDateString('en-us', { month: 'long' })
  const weekStart = currentDate.getDate()
  const weekEnd = weekStart - 7
  const year = currentDate.getFullYear()

  return {
    newDate,
    monthName,
    weekStart,
    weekEnd,
    year,
    currentDate,
  }
}
