import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { ToastContainer, toast } from 'react-toastify'

import Left from '../../../assets/images/left.svg'
import ProgramHistoryCard from './program-history-card'
import Api from '../../../utils/api'

function PastProgramLoader() {
  return (
    <div className="past-program-card gray">
      <div className="past-program-container">
        <div className="program-top-card-content">
          <div className="d-flex align-items-center">
            <Skeleton height={21} width={22} />
            <span className="ml-3 program-card-title fw-700">
              <Skeleton width={170} />
            </span>
          </div>
        </div>
        <div className="d-flex row align-items-start mt-3">
          <div className=" col-4">
            <div className="program-card-label">
              <Skeleton />
            </div>
            <div className="program-value">
              <Skeleton height={15} width={80} />
            </div>
          </div>
          <div className="col-4 ws-nowrap ">
            <div className="program-card-label">
              <Skeleton />
            </div>
            <div className="program-value">
              <Skeleton height={15} width={80} />
            </div>
          </div>
          <div className="col-4">
            <div className="program-card-label">
              <Skeleton width={80} />
            </div>
            <div className="program-value">
              <Skeleton height={15} width={80} />
            </div>
          </div>
          <div className="mt-3">
            <div className="program-card-label">
              <Skeleton width={80} />
            </div>
            <div className="program-value ">
              <Skeleton height={15} width={130} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function PastPrograms() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    Api.get('api/pastprograms')
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false)
          setData(res?.data?.data)
        }
      })
      .catch(() => toast.error('Something went wrong'))
    smartech('dispatch', 'Page Browse', {
      pageurl: window.location.href,
    })
  }, [])

  return (
    <React.Fragment>
      <div className="past-programs-header d-flex">
        <div className="back-arrow-container d-flex justify-content-center align-items-center">
          <img
            alt="left arrow"
            className="back-arrow"
            role="presentation"
            src={Left}
            onClick={() =>
              setTimeout(() => {
                navigate('/callback')
              }, 300)
            }
          />
        </div>
        <div className="past-programs-text f-20 fw-700">Program History</div>
      </div>
      <div style={{ overflow: 'auto', height: 'calc(100vh - 130px)' }}>
        {loading ? (
          <React.Fragment>
            <PastProgramLoader />
            <PastProgramLoader />
            <PastProgramLoader />
          </React.Fragment>
        ) : (
          <div>
            {data && data.length > 0 ? (
              data.map((el) => <ProgramHistoryCard key={el} data={el} />)
            ) : (
              <div
                className="d-flex justify-content-center align-items-center indigo-blue fw-bold f-20 line-24"
                style={{ overflow: 'auto', height: 'calc(100vh - 130px)' }}
              >
                There are no records available
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        hideProgressBar
        pauseOnHover
        autoClose={3000}
        className="f-12 fw-400"
        limit={1}
        position="bottom-center"
      />
    </React.Fragment>
  )
}

export default PastPrograms
