import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import validator from 'validator'
import { Modal } from '../../../component/CommonComponent'
import {
  BENEFIT_SECTION,
  POINTS_SECTION,
  BENEFIT_OUTCOME_SECTION,
  QUESTIONS_ANSWER_SECTION,
} from '../../../utils/constant'
import useOnClickOutside from '../../../hooks/useOnclickOutside'
import Left from '../../../assets/images/left.svg'
import PriceBadge from '../../../assets/images/price-badge-icon.svg'
import InsuredIcon from '../../../assets/images/insurance-icon.svg'
import RightArrow from '../../../assets/images/right.svg'
import StatusCheck from '../../../assets/images/status-check.svg'
import CardCurve from '../../../assets/images/card-curve-design.svg'
import Expand from '../../../assets/images/fq-expand-icon.svg'
import Collapse from '../../../assets/images/fq-collapse-icon.svg'
import AppointmentSuccess from '../../appointment-success'
import RaiseCallRequest from '../../../components/dashboard/RaiseCallRequest'
import api from '../../../utils/api'

const settings = {
  infinite: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: false,
  customPaging: () => <div className="slick-bar" />,
}

const setting = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: false,
  centerMode: true,
  customPaging: () => <div className="slick-bar" />,
}

function ProgramDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [benefitSection, setBenefitSection] = useState(false)
  const [,setEmail] = useState(localStorage.getItem('email') || '')
  const [mobile, setMobile] = useState(localStorage.getItem('mobile') || '')
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
  const [scheduleMessage, setScheduleMessage] = useState('')
  const [activeTitle, setActiveTitle] = useState('')
  const [, setStep] = useState('')
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showTimeSlots, setShowTimeSlots] = useState(false)
  const [showUserMobileSection, setShowUserMobileSection] = useState(false)
  const [path, setPath] = useState('')
  const ref = useRef()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    title,
    numberOfWeeks,
    partneredBy,
    wordPressProgramId,
    programOverviewDetails,
    programDescription,
    price,
    discountedPrice,
    partnerName,
    backGroundImage,
    enrolledProgram,
    profileData,
  } = location.state

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const handleBenefitSection = (activeSection) => {
    setIsModalOpen(true)
    setBenefitSection(true)
    setActiveTitle(activeSection)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setMobile(localStorage.getItem('mobile') || '')
    setEmail(localStorage.getItem('email') || '')
    setBenefitSection(false)
    setError('')
    setDisabled(false)
    setShowDatePicker(false)
    setShowTimeSlots(false)
    setShowUserMobileSection(false)
  }

  useOnClickOutside(ref, () => handleModalClose())

  const showAnswer = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id))
    const answerSection = document.getElementById(`answer-section-${id}`)
    if (answerSection) {
      answerSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleProgramEnroll = () => {
    smartech('dispatch', 'Page Browse', {
      pageurl: `${window.location.href}/hc_consultation/initiated`,
    })
    setBenefitSection(false)
    if (partneredBy) {
      setIsModalOpen(true)
      setStep(10)
    } else {
      setIsModalOpen(true)
    }
  }
  const validateMobileNumber = (mobileNO) => {
    const errors = {}
    if (!validator.isMobilePhone(mobileNO, 'en-IN')) {
      errors.mobileNO = 'Please enter the valid contact number.'
    }

    return { errors, isValid: Object.keys(errors).length <= 0 }
  }


  const handleScheduleCall = (e, selectedDate, selectedTimeslot) => {
    setDisabled(true)
    e.preventDefault()
    setPath(`${window.location.href}/hc_consultation/success`)
    const { errors, isValid } = validateMobileNumber(mobile)
    if (isValid) {
      const payload = {
        reason: "Program Information Requested",
        consultation_date: moment(selectedDate).format('YYYY-MM-DD'),
        timeslot_id: selectedTimeslot,
        mobile_number: mobile.replace('+91', ''),
        type: 'task_consultation',
        preferred_program_id: wordPressProgramId,
      }
      api
        .post('api/callrequests', payload)
        .then((res) => {
          if (res.data?.status !== 'error') {
            setScheduleMessage(res?.data?.message)
            setIsModalOpen(false)
            setSuccessModalOpen(true)
            setDisabled(false)
            const timer = setTimeout(() => {
              setTimeout(() => {
                handleModalClose()
                setSuccessModalOpen(false)
                setDisabled(false)
                navigate('/callback')
              }, 1000)
            }, 3000)
            return () => clearTimeout(timer)
          }
          toast.error(res?.data?.message)
          setDisabled(false)
          setIsModalOpen(false)
        })
        .catch((err) => {
          setError(err?.response?.data?.message)
          setDisabled(false)
        })
    } else {
      setError(errors.mobile)
      setDisabled(false)
    }
  }
  return (
    <div className="program-overview-section">
      <div className="all-programs-header d-flex">
        <div className="back-arrow-container d-flex justify-content-center align-items-center">
          <img
            alt="left arrow"
            className="back-arrow"
            role="presentation"
            src={Left}
            onClick={() =>
              setTimeout(() => {
                navigate(-1)
              }, 300)
            }
          />
        </div>
        <div>
          <div className="all-programs-text f-20 fw-700">
            <p>{title}</p>
            <p className="f-14 fw-300">{numberOfWeeks} weeks</p>
          </div>
        </div>
      </div>
      <div className="program-details-section">
        <div className="program-description">
          <div
            className="background-image"
            style={{ backgroundImage: `url(${backGroundImage})` }}
          />
          <div className="program-information">
            <p className="f-12 fw-400 line-16">{programDescription}</p>
          </div>
          <div className="program-pricetag">
            <img alt="price-badge" src={PriceBadge} />
            <p className="f-14 fw-400 line-12 text-decoration-line-through">
              &#8377;{price}
            </p>
            <p className="indigo-blue f-14 fw-700"> &#8377;{discountedPrice}</p>
          </div>
          <div className="program-sponser">
            <img alt="insured" src={InsuredIcon} />
            {partneredBy ? (
              <p className="indigo-blue f-10 fw-700 line-9">
                Partnered by {partnerName}
              </p>
            ) : (
              <p className="indigo-blue f-10 fw-700 line-9">
                Sponsored by your organization
              </p>
            )}
          </div>
        </div>
        {programOverviewDetails
          .filter(
            (section) => section.content[0].acf_fc_layout === BENEFIT_SECTION
          )
          .map((section) => (
            <div key={section.id} className="program-benefits-section mt-4">
              <p className="f-18 fw-700 line-24 ms-4">{section.title}</p>
              {section.content[0].content.map((list) => (
                <div key={list.id}>
                  <div
                    className="benefits-list d-flex align-items-center justify-content-around mt-4 w-100"
                    role="presentation"
                    onClick={() => handleBenefitSection(list?.title)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="benefit-icon">
                        <img alt="benefit" src={list.icon.url} />
                      </div>
                      <div className="benefit-title">
                        <p className="secondary-black f-12 fw-400 line-16">
                          {list.title}
                        </p>
                      </div>
                    </div>
                    <div className="benefit-arrow-icon">
                      <img alt="right-arrow" src={RightArrow} />
                    </div>
                  </div>
                  <div className="line-border" />
                </div>
              ))}
            </div>
          ))}

        {programOverviewDetails
          .filter(
            (section) => section.content[0].acf_fc_layout === POINTS_SECTION
          )
          .map((section) => (
            <div key={section.id} className="eligiblity-section">
              <div className="eligibilty-heading">
                <p className="f-18 fw-700 line-24 ms-4 mt-4">{section.title}</p>
              </div>
              {section.content[0].content.map((points) => (
                <div key={points.id} className="elegibilty-list d-flex">
                  <div>
                    <img alt="status-check" src={StatusCheck} />
                  </div>
                  <div>
                    <p className="f-14 fw-400 line-21">{points.points}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        {programOverviewDetails
          .filter(
            (section) =>
              section.content[0].acf_fc_layout === BENEFIT_OUTCOME_SECTION
          )
          .map((section) => (
            <div key={section.id}>
              <p className="f-18 fw-700 line-24 ms-4 mt-4">{section.title}</p>

              <div className="benefit-outcome-section">
                <Slider {...settings}>
                  {section.content[0].content.map((content) => (
                    <div
                      key={content.id}
                      className="benefit-outcome-card d-flex justify-content-between mt-3"
                    >
                      <div className="outcome-card-heading">
                        <p className="f-14 fw-700 line-18 p-3">
                          {content.title}
                        </p>
                        <p className="f-12 fw-400 line-16 ps-3">
                          {content.description}
                        </p>
                      </div>
                      <div className="benefit-card-image-section position-relative">
                        <img alt="card-curve" src={CardCurve} />
                        <img
                          alt="benefit"
                          className="benefit-card-icon"
                          src={content.icon.url}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ))}
        {programOverviewDetails
          .filter(
            (section) =>
              section.content[0].acf_fc_layout === QUESTIONS_ANSWER_SECTION
          )
          .map((section) => (
            <div key={section.id} className="f-Q-section">
              <div className="f-Q-heading">
                <p className="f-18 fw-700 line-24 ps-4">{section.title}</p>
              </div>
              {section.content[0].content.map((content, index) => {
                Object.assign(content, {
                  id: index + 1,
                })
                return (
                  <React.Fragment key={content?.id}>
                    <div
                      className="f-q-list mt-4"
                      role="presentation"
                      onClick={() => showAnswer(content.id, index)}
                    >
                      <p className="f-16 fw-600 line-22 ms-4">
                        {content.question}
                      </p>
                      <img
                        alt="expand"
                        className="expand-collapse-icon ms-4"
                        src={activeId === content?.id ? Collapse : Expand}
                      />
                    </div>
                    <div
                      className={`answer-section ${
                        activeId === content?.id ? 'd-block' : 'd-none'
                      }`}
                    >
                      <p className="f-12 fw-400 line-19">{content.answer}</p>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          ))}
      </div>
      <button
        className="enroll-program-btn w-100 position-absolute bottom-0"
        disabled={enrolledProgram?.program_name !== 'No Plan'}
        type="button"
        onClick={handleProgramEnroll}
      >
        Request to Enroll
      </button>
      <Modal
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        modalRef={ref}
      >
        {benefitSection ? (
          <React.Fragment>
            {programOverviewDetails
              .filter(
                (section) =>
                  section.content[0].acf_fc_layout === BENEFIT_SECTION
              )
              .map((content) => (
                <div key={content?.id}>
                  <Slider {...setting}>
                    {content.content[0].content.map((item) => {
                      if (item.title === activeTitle) {
                        return (
                          <div
                            key={item.id}
                            className="benefit-modal-section d-flex flex-column justify-center"
                          >
                            <div>
                              <p className="f-16 fw-500 line-24">
                                {item.title}
                              </p>
                            </div>
                            <img
                              alt="program-benefits"
                              src={item.background_image.url}
                            />
                            <div className="benefit-modal-description">
                              <p className="f-14 fw-600">{item.description}</p>
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                    {content.content[0].content.map((item) => {
                      if (item.title !== activeTitle) {
                        return (
                          <div
                            key={item.id}
                            className="benefit-modal-section d-flex flex-column justify-center"
                          >
                            <div>
                              <p className="f-16 fw-500 line-24">
                                {item.title}
                              </p>
                            </div>
                            <img
                              alt="program-benefits"
                              src={item.background_image.url}
                            />
                            <div className="benefit-modal-description">
                              <p className="f-14 fw-600">{item.description}</p>
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                  </Slider>
                </div>
              ))}
            <button
              className="enroll-program-btn"
              disabled={enrolledProgram?.program_name !== 'No Plan'}
              type="button"
              onClick={handleProgramEnroll}
            >
              Request to Enroll
            </button>
          </React.Fragment>
        ) : (
          <RaiseCallRequest
            disabled={disabled}
            error={error}
            handleScheduleCall={handleScheduleCall}
            mobile={mobile}
            profileData={profileData}
            setDisabled={setDisabled}
            setError={setError}
            setMobile={setMobile}
            setShowDatePicker={setShowDatePicker}
            setShowTimeSlots={setShowTimeSlots}
            setShowUserMobileSection={setShowUserMobileSection}
            showDatePicker={showDatePicker}
            showTimeSlots={showTimeSlots}
            showUserMobileSection={showUserMobileSection}
          />
        )}
      </Modal>
      {isSuccessModalOpen && (
        <AppointmentSuccess
        handleModalClose={handleModalClose}
        redirectedFrom={path}
        scheduleMessage={scheduleMessage}
        setSuccessModalOpen={setSuccessModalOpen}
      />
      )}
    </div>
  )
}
export default ProgramDetails
