import React, { memo, useState } from 'react'
import moment from 'moment'
import getMonth from 'date-fns/getMonth'
import DatePicker from 'react-datepicker'
import isEmail from 'validator/lib/isEmail'

import StatusStepper from './StatusStepper'
import API from '../../utils/api'

import RightArrow from '../../assets/images/right-arrow.svg'
import LeftIcon from '../../assets/images/left.svg'
import { months } from '../../utils/constant'
import RightIcon from '../../assets/images/right.svg'

const CallbackRequest = memo(
  ({
    step,
    mobile,
    email,
    error,
    timeSlots,
    selectedTimeSlot,
    selectedDate,
    setStep,
    speciality,
    callSpeciality,
    today,
    time,
    setSelectedTimeSlot,
    minDate,
    maxDate,
    setSelectedDate,
    handleSubmit,
    setMobile,
    setEmail,
    setError,
    isRescheduleCallModal,
    handleReschedule,
    mhiSpeciality,
    programSpeciality,
    disabled,
  }) => {
    const [btnDisable, setBtnDisable] = useState(false)
    const rescheduleCall = () => {
      if (isRescheduleCallModal === 1) {
        return `${speciality}`
      } else if (!callSpeciality && !mhiSpeciality) {
        return 'Health Coach'
      } else if (callSpeciality !== undefined) {
        return `${callSpeciality}`
      }
      return `${mhiSpeciality}`
    }
    const handleConfirm = () => {
      const payload = {
        email,
      }
      API.post('api/updateuserdetails', payload)
        .then((res) => {
          if (res?.data?.status_code === 200) {
            setStep(8)
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.message)
        })
    }

    const handleEmail = (e) => {
      setEmail(e.target.value)
      if (email.length > 1) {
        const emailValidate = isEmail(email)
        if (!emailValidate) {
          setError('Please enter the valid Email')
          setBtnDisable(true)
        } else if (emailValidate) {
          setError('')
          setBtnDisable(false)
        }
      } else {
        setError('')
        setBtnDisable(true)
      }
    }
    if (step === 10) {
      return (
        <div>
          <StatusStepper step={step} />
          <div className="text-center">
            <p className="f-18 fw-700 line-25">Confirm your Email ID</p>
            <p className="f-14 fw-400 line-19 mt-2">
              Please confirm or update the email id in the space given below
            </p>
            <div className="input-group mt-3">
              <input
                className="form-control text-center"
                type="email"
                value={email}
                onChange={handleEmail}
              />
            </div>
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: '35px' }}
            >
              <p className="error-color f-12 fw-400 line-16">{error}</p>
            </div>
            <button
              className={`${
                !btnDisable ? 'confirm-appointment-btn' : 'confirm-btn-disable'
              } w-100 line-18 f-16 fw-bold mt-0`}
              disabled={btnDisable}
              type="button"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      )
    } else if (step === 8) {
      return (
        <React.Fragment>
          <StatusStepper step={step} />
          <div className="text-center">
            <p className="f-16 fw-600m line-22 dark-gray">
              Set a call back request from
            </p>
            <p className="f-20 fw-700 line-22 pt-1">
              {programSpeciality || 'Health Coach'}
            </p>
            <p className="f-14 fw-700 line-16 pt-4 dark-gray">
              Select Preferred Date & Time Slot
            </p>
          </div>
          <div className="time-slot">
            <button
              className="time-slot-btn f-16 line-22"
              type="button"
              onClick={() => setStep(6)}
            >
              {moment(selectedDate).format('DD MMMM YYYY')}
            </button>
            <button
              className="time-slot-btn f-16 line-22"
              type="button"
              onClick={() => setStep(4)}
            >
              {timeSlots && timeSlots.length > 0
                ? timeSlots.find((itm) => itm._id === selectedTimeSlot)?.label
                : selectedTimeSlot}
            </button>
          </div>
          <button
            className="confirm-appointment-btn line-18 f-16 fw-bold"
            type="button"
            onClick={handleSubmit}
          >
            Send Activation Request
          </button>
        </React.Fragment>
      )
    } else if (step === 0) {
      return (
        <React.Fragment>
          <div className="mb-4">
            <p className="mb-0 f-16 line-22 charcoal-grey fw-600">
              {isRescheduleCallModal === 1
                ? 'Reschedule call with'
                : 'Schedule call with'}
            </p>
            <p className="mb-0 f-20 line-28 fw-bold">
              {programSpeciality || rescheduleCall()}
            </p>
          </div>
          <div>
            <p className="fw-bold f-14 line-16 dark-gray">
              Select Preferred Time Slot
            </p>
            <div className="time-slot">
              <button
                className="time-slot-btn f-16 line-22"
                type="button"
                onClick={() => setStep(1)}
              >
                {moment(selectedDate).format('DD MMMM YYYY')}
              </button>
              <button
                className="time-slot-btn f-16 line-22"
                type="button"
                onClick={() => setStep(2)}
              >
                {timeSlots && timeSlots.length > 0
                  ? timeSlots.find((itm) => itm._id === selectedTimeSlot)?.label
                  : selectedTimeSlot}
              </button>
            </div>
          </div>
          <button
            className="mt-4 confirm-appointment-btn line-18 f-16 fw-bold"
            disabled={disabled}
            type="button"
            onClick={(e) => {
              if (isRescheduleCallModal === 1) {
                handleReschedule(e)
              } else {
                setStep(3)
              }
            }}
          >
            Confirm Appointment
          </button>
        </React.Fragment>
      )
    } else if (step === 1 || step === 6) {
      return (
        <React.Fragment>
          <DatePicker
            inline
            formatWeekDay={(nameOfDay) => nameOfDay.toString().substr(0, 1)}
            maxDate={maxDate}
            minDate={minDate}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div className="d-flex justify-content-between mb-4">
                <div className="fw-bold f-16 line-18">Select Date</div>
                <div className="fw-bold f-14 line-16 d-flex align-items-center">
                  <img
                    alt="left arrow"
                    className="me-4"
                    role="presentation"
                    src={LeftIcon}
                    onClick={decreaseMonth}
                  />
                  {months[getMonth(date)]}
                  <img
                    alt="right arrow"
                    className="ms-4"
                    role="presentation"
                    src={RightIcon}
                    onClick={increaseMonth}
                  />
                </div>
              </div>
            )}
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
          <div className="d-flex justify-content-between select-date-next-btn">
            <p className="mb-0 fw-bold f-14 line-18">
              {moment(selectedDate).format('ddd, D MMM')}
            </p>
            <p
              className="mb-0 indigo-blue fw-bold f-16 line-18"
              role="presentation"
              onClick={() => (step === 6 ? setStep(4) : setStep(2))}
            >
              Next <img alt="right arrow" src={RightArrow} />
            </p>
          </div>
        </React.Fragment>
      )
    } else if (step === 2 || step === 4) {
      return (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0 fw-600 f-20 line-24">
                {moment(selectedDate).format('dddd')}
              </p>
              <p className="mb-0 fw-600 f-16 line-24">
                {moment(selectedDate).format('DD MMM YYYY')}
              </p>
            </div>
            <div
              className="indigo-blue fw-bold f-16 line-16"
              role="presentation"
              onClick={() => (step === 4 ? setStep(6) : setStep(1))}
            >
              Change
            </div>
          </div>
          <div className="mt-38">
            <h6 className="fw-600 f-20 line-24 mb-4">Select time</h6>
            <ul className="time-span p-0 mb-5 text-center">
              {timeSlots &&
                timeSlots.length > 0 &&
                timeSlots.map((itm) => (
                  <li
                    key={itm?._id}
                    className={`fw-bold f-14 line-18 ${
                      itm._id === selectedTimeSlot ? 'active' : ''
                    } ${
                      moment(selectedDate).format('l') ===
                        moment(today).format('l') && time + 1 > itm.start_time
                        ? 'disable'
                        : ''
                    }`}
                    role="presentation"
                    onClick={() => {
                      if (
                        moment(selectedDate).format('l') ===
                          moment(today).format('l') &&
                        time + 1 < itm.start_time
                      ) {
                        setSelectedTimeSlot(itm?._id)
                      } else if (
                        moment(selectedDate).format('l') !==
                        moment(today).format('l')
                      ) {
                        setSelectedTimeSlot(itm?._id)
                      }
                    }}
                  >
                    {itm?.label}
                  </li>
                ))}
            </ul>
          </div>
          <button
            className="confirm-appointment-btn line-18 f-16 fw-bold"
            type="button"
            onClick={() => (step === 4 ? setStep(8) : setStep(0))}
          >
            Confirm
          </button>
        </React.Fragment>
      )
    }

    const scheduleCall = () => {
      if (!callSpeciality && !mhiSpeciality) {
        return 'Health Coach'
      }
      if (callSpeciality !== undefined) {
        return `${callSpeciality}`
      }
      return `${mhiSpeciality}`
    }

    return (
      <React.Fragment>
        <p className="mb-0 fw-bold f-18 line-24 text-center mb-4">
          Confirm your mobile number
        </p>
        <p className="mb-0 f-16 line-22 text-center px-4 mb-4">
          You will receive a call on this number from the {scheduleCall()}
        </p>
        <div className="input-group px-5">
          <span className="input-group-text" id="basic-addon1">
            +91
          </span>
          <input
            className="form-control"
            type="number"
            value={mobile}
            onChange={(e) => {
              if (e.target.value.length === 11) return false
              setMobile(e.target.value)
              setError('')
            }}
          />
          <div className="invalid-feedback d-block f-12 line-16 text-center error-color mt-3">
            {error}
          </div>
        </div>
        <button
          className={`${
            mobile.length === 10
              ? 'confirm-appointment-btn'
              : 'confirm-btn-disable'
          } line-18 f-16 fw-bold mt-5`}
          disabled={mobile.length !== 10 || disabled}
          type="button"
          onClick={handleSubmit}
        >
          Confirm Mobile Number
        </button>
      </React.Fragment>
    )
  }
)

export default CallbackRequest
