import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

function DeliTimePicker({ className, onChange, value, ...rest }) {
  return (
    <TimePicker
      {...rest}
      hideDisabledOptions
      open
      use12Hours
      allowEmpty={false}
      className={className}
      header={false}
      popupClassName={className}
      showSecond={false}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeliTimePicker
