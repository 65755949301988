import { memo } from 'react'

import CloseIcon from '../../assets/images/close.svg'

const Modal = memo(
  ({ isModalOpen, handleModalClose, children, isHeightFixed, modalRef }) => (
    <div className={`modal-footer  ${isModalOpen ? 'd-block' : 'd-none'}`}>
      <div
        ref={modalRef}
        className={`modal-content ${
          !isHeightFixed ? 'animated slideInUp' : ''
        }`}
        style={{ height: isHeightFixed ? '250px' : 'auto' }}
      >
        <img
          alt="modal close icon"
          className="close"
          role="presentation"
          src={CloseIcon}
          onClick={handleModalClose}
        />
        {children}
      </div>
    </div>
  )
)

export default Modal
