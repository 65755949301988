import React, { memo, useState } from 'react'

import CollapseIcon from '../../assets/images/collapse.svg'
import UpArrow from '../../assets/images/up-arrow.svg'
import DownArrow from '../../assets/images/down-arrow.svg'
import SuccessImage from '../../assets/images/success-image.svg'

const VitalsCard = memo(
  ({
    className,
    heading_title,
    handleClick,
    no_of_weeks,
    sub_text1,
    sub_text_value1,
    sub_text2,
    sub_text_value2,
    sub_text3,
    sub_text_value3,
    icon,
    mass,
    disableBtn,
    last_modified_date,
    children,
    handleViewHistoryClick,
  }) => {
    const [isCollapse, setIsCollapse] = useState(false)
    const weightArrow = () => {
      if (heading_title === 'Body Weight' && sub_text_value1 > 0) {
        return (
          <span>
            <img alt="up arrow" src={UpArrow} />
          </span>
        )
      } else if (heading_title === 'Body Weight' && sub_text_value1 < 0) {
        return (
          <span>
            <img alt="down arrow" className="downward" src={DownArrow} />
          </span>
        )
      }
      return null
    }

    const bodyWeightvolume = () => {
      if (sub_text_value1 === 0) {
        return 'volume-quantity'
      } else if (sub_text_value1 > 0) {
        return 'volumeIncrease'
      }
      return 'volumeDecrease'
    }

    const headingTitle = () => {
      if (heading_title === 'Blood Glucose') {
        return sub_text_value2
      } else if (heading_title === 'Body Weight') {
        return Number(sub_text_value2).toFixed(1)
      }
      return Math.round(sub_text_value2)
    }

    return (
      <div className={`track-card ${className}`}>
        <div className="card-container">
          <div className="top-card-content">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img alt="vital icon" src={icon} width="21px" />
                <span className={`ml-3 card-title fw-700 ${className}`}>
                  {heading_title}
                </span>
              </div>
              <div>
                <span className={`week-count fw-400 ${className}`}>
                  {no_of_weeks === 'Completed' ? (
                    <React.Fragment>
                      <img alt="success" src={SuccessImage} />
                      <span className="completeText">{no_of_weeks}</span>
                    </React.Fragment>
                  ) : (
                    no_of_weeks
                  )}
                </span>
                <img
                  alt="collapse"
                  className={`ms-2 cursor-pointer ${
                    isCollapse ? 'rotate-180' : ''
                  }`}
                  role="presentation"
                  src={CollapseIcon}
                  onClick={() => setIsCollapse((collapse) => !collapse)}
                />
              </div>
            </div>
            {last_modified_date && (
              <div className="mt-1 f-10 light-gray fw-400">
                <span>
                  last recorded on
                  {last_modified_date}
                </span>
              </div>
            )}
          </div>

          <div className="d-flex row align-items-start">
            <div className="first col-4">
              <div className="card-label">{sub_text1}</div>
              <div className="volume">
                {weightArrow()}

                <div>
                  {heading_title === 'Body Weight' ? (
                    <span className={bodyWeightvolume()}>
                      {Math.abs(sub_text_value1).toFixed(1)}
                    </span>
                  ) : null}
                  {heading_title !== 'Body Weight' ? (
                    <span
                      className={
                        sub_text_value1 > 0
                          ? 'volume-Change'
                          : 'volume-quantity'
                      }
                    >
                      {Math.round(sub_text_value1)}
                    </span>
                  ) : null}
                  <span className="volume-label">{mass}</span>
                </div>
              </div>
            </div>
            <div className="col-4 ws-nowrap ">
              <div className="card-label">{sub_text2}</div>
              <div className="volume">
                <span
                  className={
                    sub_text_value2 === 0 || sub_text_value2 === '00-00'
                      ? 'volume-quantity'
                      : 'volume-Change'
                  }
                >
                  {headingTitle()}
                </span>
                <span className="volume-label">{mass}</span>
              </div>
            </div>
            {sub_text3 && (
              <div className="col-4">
                <div className="card-label">{sub_text3}</div>
                <div className="volume">
                  <span
                    className={
                      sub_text_value3 > 0 ? 'volume-Change' : 'volume-quantity'
                    }
                  >
                    {sub_text_value3 ? Number(sub_text_value3).toFixed(1) : 0}
                  </span>
                  <span className="volume-label">{mass}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="open" style={{ height: `${isCollapse ? '' : '0px'}` }}>
          <div className="px-2 py-4">{children}</div>
        </div>
        <div className="d-flex">
          <button
            className="btn-view-history fw-bold line-26"
            type="button"
            onClick={handleViewHistoryClick}
          >
            View history
          </button>
          <button
            className="btn-measure fw-bold line-26"
            disabled={disableBtn}
            type="button"
            onClick={handleClick}
          >
            Measure
          </button>
        </div>
      </div>
    )
  }
)

export default VitalsCard
