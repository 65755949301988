import icon from '../../../assets/images/BloodPressureIcon.svg'

function ProgramHistoryCard({ data }) {
  const { program_name, duration, start_date, end_date, reason } = data

  return (
    <div className="past-program-card ">
      <div className="past-program-container">
        <div className="program-top-card-content">
          <div className="d-flex align-items-center">
            <img alt=" icon" height="21px" src={icon} width="21px" />
            <span className="ml-3 program-card-title fw-700 f-14 indigo-blue">
              {program_name}
            </span>
          </div>
        </div>
        <div className="d-flex row align-items-start">
          <div className="col-4 mt-3">
            <div className="program-card-label">Duration</div>
            <div className="program-value">
              <div>{duration}</div>
            </div>
          </div>
          <div className="col-4 ws-nowrap mt-3">
            <div className="program-card-label">Start Date</div>
            <div className="program-value">
              <span>{start_date}</span>
            </div>
          </div>

          <div className="col-4 mt-3">
            <div className="program-card-label">End Date</div>
            <div className="program-value">
              <span>{end_date}</span>
            </div>
          </div>
          <div className="mt-3">
            <div className="program-card-label">Reason</div>
            <div className="program-value">
              <span>{reason}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramHistoryCard
