import React, { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  YAxis,
  XAxis,
  CartesianGrid,
  ReferenceLine,
} from 'recharts'
import Skeleton from 'react-loading-skeleton'
import { DateSelector, DateFetcher } from '../DateSelector'
import StepLogo from '../../../assets/images/step-tracker-icon.svg'
import gfitApi from '../../../utils/gfitApi'
import {
  Activities,
  ChartContainer,
  Logo,
  StepTrack,
  StepContent,
  StepText,
  ChartHeader,
  ChartBody,
} from '../GfitComponent'
import { ActiveMin, Calorie } from '../ActivityCards'
import { loadingTrue, loadingFalse } from '../../../utils/helper'
// eslint-disable
import {
  ActivityCardLoader,
  ChartLoader,
  StepCountLoader,
} from '../StepTrackerLoader'
// eslint-enable
function DailyCharts({ handleStepCount, activity_id }) {
  const [date, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [stepsValue, setStepsValue] = useState([])
  const [stepCount, setStepCount] = useState('')
  const [kcal, setKcal] = useState('')
  const [activeMin, setActiveMin] = useState('')
  const [activityValue, setActivityValue] = useState('')
  const [loading, setLoading] = useState(true)

  const steplable = (i) => {
    if (i < 10) {
      return `0${i}:00`
    } else if (i < 23) {
      return `${i}:00`
    }
    return '23:59'
  }

  const setDailyStepData = (newStepCount) => {
    const stepData = newStepCount.sessions
    const dailyStepData = []
    stepData.forEach((item, i) => {
      dailyStepData.push({
        startTime: item.startTime,
        endTime: item.endTime,
        value: item.value,
        label: steplable(i),
        // label: i < 10 ? `0${i}:00` : i < 23 ? `${i}:00` : '23:59',
      })
    })
    setStepsValue(dailyStepData)
  }

  const liveGfitData = (startTime, endTime) => {
    const finalStartTime = new Date(startTime).toUTCString()
    const finalEndTime = new Date(endTime).toUTCString()
    gfitApi
      .get(
        `api/live-gfit?startTime='${finalStartTime}'&endTime='${finalEndTime}'&duration=3600000` +
          `&activityId=${activity_id}`
      )
      .then((res) => {
        if (res.status === 200) {
          setStepCount(res.data.stepsHistory.stepCount.integerValue)
          setKcal(
            Math.round(res.data.stepsHistory.caloriesBurnt.floatingPointValue)
          )
          setActiveMin(res.data.stepsHistory.activeMinutes.integerValue)
          setDailyStepData(res.data.stepsHistory.stepCount)
          handleStepCount(
            res.data.stepsHistory.stepCount.integerValue,
            res.status
          )
          setActivityValue(res.data.activityDetails.value)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        handleStepCount(err.response)
      })
  }

  const handleStepData = (res) => {
    if (res.status === 200) {
      setStepsValue('')
      setDailyStepData(res.data.stepsHistory.stepCount)
      handleStepCount(res.data.stepsHistory.stepCount.integerValue, res.status)
      setStepCount(res.data.stepsHistory.stepCount.integerValue)
      setKcal(
        Math.round(res.data.stepsHistory.caloriesBurnt.floatingPointValue)
      )
      setActiveMin(res.data.stepsHistory.activeMinutes.integerValue)
      setActivityValue(res?.data?.activityDetails?.value)
      document.dispatchEvent(loadingFalse)
    } else {
      document.dispatchEvent(loadingFalse)
    }
  }
  const handleLoading = () => {
    document.dispatchEvent(loadingTrue)
  }

  useEffect(() => {
    const dateValues = DateFetcher()
    const { newDate, monthName, currentDate } = dateValues
    setDate(newDate)
    setMonth(monthName)
    liveGfitData(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ),
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      )
    )
  }, [])

  return (
    <React.Fragment>
      {loading ? (
        <StepCountLoader />
      ) : (
        <StepContent width="100%">
          <StepText color="white" lineHeight="65px">
            {stepCount || (stepCount === 0 ? 0 : '--')}
          </StepText>
          <StepText color="#E0DEFC" lineHeight="22px" size="16px" weight="600">
            Todays Goal : {activityValue} steps
          </StepText>
        </StepContent>
      )}
      <ChartContainer>
        <ChartHeader>
          <DateSelector
            activity_id={activity_id}
            date={date}
            handleLoading={handleLoading}
            handleStepData={handleStepData}
            month={month}
          />
          <StepTrack>
            <Logo left="-3px" position="relative" src={StepLogo} />
            {loading ? (
              <Skeleton height={8} width={30} />
            ) : (
              <StepText color="#5A5A5A" size="11px" weight="400">
                {stepCount || (stepCount === 0 ? 0 : '--')} Steps
              </StepText>
            )}
          </StepTrack>
        </ChartHeader>
        {loading ? (
          <ChartLoader />
        ) : (
          <ChartBody>
            <BarChart
              data={stepsValue}
              height={200}
              margin={{
                top: 0,
                bottom: 5,
              }}
              style={{
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
              }}
              width={375}
            >
              <CartesianGrid strokeDasharray="1" vertical={false} />

              <Bar
                barSize={5}
                dataKey="value"
                fill="#59C4BA"
                name="label"
                radius={5}
              />

              <YAxis
                axisLine={false}
                dx={-5}
                dy={-5}
                hide={false}
                tickLine={false}
              />
              <XAxis
                axisLine={false}
                dataKey="label"
                interval="preserveStartEnd"
                minTickGap={2}
                tickLine={false}
                tickSize={10}
              />
              <ReferenceLine
                stroke="#494E9D"
                strokeDasharray="5 5"
                y={activityValue}
              />
            </BarChart>
          </ChartBody>
        )}
      </ChartContainer>
      <Activities>
        {loading ? <ActivityCardLoader /> : <Calorie kcal={kcal} />}
        {loading ? <ActivityCardLoader /> : <ActiveMin activeMin={activeMin} />}
      </Activities>
    </React.Fragment>
  )
}
export default DailyCharts
