import { memo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import InformationCircleBlue from '../../assets/images/information-circle-blue.svg'

const TrackVitalHeader = memo(
  ({ heading, last_updated_time, icon, weekNumber, handleModalOpen }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const handleNavigate = () => {
      navigate('/vitals', {
        state: {
          weekNumber,
        },
      })
    }

    return (
      <div>
        <div className="track-vital-header d-flex justify-content-between">
          <div className="f-15 align-items-center ">
            <img
              alt="vital header"
              className="back-arrow"
              role="presentation"
              src={icon}
              onClick={handleNavigate}
            />
          </div>
          <div className="vital-text f-20 fw-700 ">
            <b>{heading}</b>
            <div className="last-updated charcoal-grey line-16 f-14 fw-300 mt-2">
              Last Updated: {last_updated_time}
            </div>
          </div>
          {!location.pathname.includes('/track-weight') && (
            <div className="info-icon">
              <img
                alt="information-circle"
                role="presentation"
                src={InformationCircleBlue}
                onClick={handleModalOpen}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default TrackVitalHeader
