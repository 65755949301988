import React, { lazy, memo, Suspense, useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'

import ErrorBoundary from '../component/ErrorBoundary'
import RoutesFile from './RoutesFile'
import { Loader, TokenExpiredModal } from '../component/CommonComponent'
import PrivacyPolicy from '../container/privacy-policy'
import TermsAndConditions from '../container/terms-and-conditions'

const NotFound = lazy(() => import('../container/404NotFound'))
const Login = lazy(() => import('../container/login'))
const Home = lazy(() => import('../container/home'))
const Bridge = lazy(() => import('../container/bridge'))

const RouteComponent = memo(({ msalInstance }) => {
  const [isLoading, setLoading] = useState(false)
  const [isTokenExpired, setTokenExpired] = useState(false)
  const [startPoint, setStartPoint] = useState(0)
  const [pullChange, setPullChange] = useState()

  const refreshCont = useRef(0)

  const initLoading = () => {
    refreshCont.current.classList.add('loading')
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0]
    setStartPoint(screenY)
  }

  const pull = (e) => {
    /**
     * get the current user touch event data
     */
    const touch = e.targetTouches[0]
    /**
     * get the touch position on the screen's Y axis
     */
    const { screenY } = touch
    /**
     * The length of the pull
     *
     * if the start touch position is lesser than the current touch position, calculate the difference, which gives the `pullLength`
     *
     * This tells us how much the user has pulled
     */
    const pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0
    setPullChange(pullLength)
  }

  const endPull = () => {
    setStartPoint(0)
    setPullChange(0)
    if (pullChange > 220) initLoading()
  }

  useEffect(() => {
    document.addEventListener('loading', (event) => {
      setLoading(event.detail)
    })
    document.addEventListener('tokenExpired', (event) => {
      setTokenExpired(event.detail)
    })
    window.addEventListener('touchstart', pullStart)
    window.addEventListener('touchmove', pull)
    window.addEventListener('touchend', endPull)
    return () => {
      window.removeEventListener('touchstart', pullStart)
      window.removeEventListener('touchmove', pull)
      window.removeEventListener('touchend', endPull)
    }
  })

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div
        ref={refreshCont}
        className=""
        style={{ marginTop: pullChange / 3.118 || '' }}
      >
        <div style={{ textAlign: 'center', marginTop: '-26px' }}>
          <svg
            className=""
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            style={{ transform: `rotate(${pullChange}deg)`, width: '24px' }}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <Router>
        <MsalProvider instance={msalInstance}>
          {isTokenExpired && (
            <TokenExpiredModal
              isModalOpen={isTokenExpired}
              setTokenExpired={setTokenExpired}
            />
          )}
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route element={<Bridge />} path="/bridge" />
              </Routes>
              <AuthenticatedTemplate>
                <Routes>
                  {RoutesFile.map((itm) => (
                    <Route
                      key={itm.name}
                      element={itm.component}
                      exact={itm.exact}
                      name={itm.name}
                      path={itm.path}
                    />
                  ))}
                  <Route element={<Home />} path="/home" />
                  <Route element={<NotFound />} path="*" />
                </Routes>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Routes>
                  <Route element={<Login />} path="/" />
                  <Route element={<Home />} path="/home" />
                  <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                  <Route
                    element={<TermsAndConditions />}
                    path="/terms-and-conditions"
                  />
                  <Route element={<NotFound />} path="*" />
                </Routes>
              </UnauthenticatedTemplate>
            </Suspense>
          </ErrorBoundary>
        </MsalProvider>
      </Router>
    </React.Fragment>
  )
})

export default RouteComponent
