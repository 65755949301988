import { lazy } from 'react'

import UnsuccessComponent from '../component/CommonComponent/UnsuccessComponent'
import TermsAndConditions from '../container/terms-and-conditions'
import SuccessScreen from '../container/success-screen'
import UnsuccessScreen from '../container/unsuccess-screen'
import Home from '../container/home'
import PrivacyPolicy from '../container/privacy-policy'
import Gfit from '../components/gFit/Gfit'
import AllPrograms from '../container/Programs/All-Programs/all-programs'
import PastPrograms from '../container/Programs/Past-Programs/past-programs'
import Report from '../container/report-page'
import ProgramDetails from '../container/Programs/Program-Overview/program-details'
import ReportSymptoms from '../container/report-symptoms'
// import { exact } from 'prop-types'

// const Dashboard = lazy(() => import('../container/dashboard'));
const DashboardV2 = lazy(() => import('../container/dashboardV2'));

const AppointmentSuccess = lazy((

) =>
  import('../container/appointment-success')
)
const GFitConnect = lazy(() => import('../container/g-fit-connect'))
const Chat = lazy(() => import('../container/chat'))
const Vitals = lazy(() => import('../container/Vitals/Vitals'))
const TrackWeightData = lazy(() =>
  import('../container/Vitals/TrackWeightData')
)
const TrackGlucoseData = lazy(() =>
  import('../container/Vitals/TrackGlucoseData')
)
const TrackBloodPressure = lazy(() =>
  import('../container/Vitals/TrackBloodPressure')
)
const Challenge = lazy(() => import('../container/challenge'))
const MyHealthPassport = lazy(() => import('../container/my-health-passport'))
const ViewHistory = lazy(() => import('../container/Vitals/ViewHistory'))

const routes = [
  {
    path: '/callback',
    exact: true,
    name: 'Dashboard',
    component: <DashboardV2 />,
  },
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    component: <DashboardV2 />,
  },
  {
    path: '/success',
    exact: true,
    name: 'Appointment Success',
    component: <AppointmentSuccess />,
  },
  {
    path: '/oauthcallback',
    exact: true,
    name: 'G Fit Connect',
    component: <GFitConnect />,
  },
  {
    path: '/chat',
    exact: true,
    name: 'Chat',
    component: <Chat />,
  },
  {
    path: '/report-page',
    exact: true,
    name: 'Report',
    component: <Report />
  },

  {
    path: '/vitals',
    exact: true,
    name: 'Vitals',
    component: <Vitals />,
  },
  {
    path: '/track-glucose',
    exact: true,
    name: 'Track Glucose',
    component: <TrackGlucoseData />,
  },
  {
    path: '/track-weight',
    exact: true,
    name: 'Track Weight',
    component: <TrackWeightData />,
  },
  {
    path: '/track-blood-pressure',
    exact: true,
    name: 'Track Blood Pressure',
    component: <TrackBloodPressure />,
  },
  {
    path: '/unsuccess-record',
    exact: true,
    name: 'UnSuccessComponent',
    component: <UnsuccessComponent />,
  },
  {
    path: '/success-screen',
    exact: true,
    name: 'Success Screen',
    component: <SuccessScreen />,
  },
  {
    path: '/unsuccess-screen',
    exact: true,
    name: 'Unsuccess Screen',
    component: <UnsuccessScreen />,
  },
  {
    path: '/challenge',
    exact: true,
    name: 'Challenge',
    component: <Challenge />,
  },
  {
    path: '/my-health-passport',
    exact: true,
    name: 'MyHealthPassport',
    component: <MyHealthPassport />,
  },
  {
    path: '/privacy-policy',
    exact: true,
    name: 'MyHealthPrivacyPolicy',
    component: <PrivacyPolicy />,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    name: 'MyHealthTermsAndConditions',
    component: <TermsAndConditions />,
  },
  {
    path: '/home',
    exact: true,
    name: 'MyHealthHomePage',
    component: <Home />,
  },
  {
    path: '/steps-history',
    exact: true,
    name: 'MyHealthGfit',
    component: <Gfit />,
  },
  {
    path: '/view-history/:id',
    exact: true,
    name: 'ViewHistory',
    component: <ViewHistory />,
  },
  {
    path: '/all-programs',
    exact: true,
    name: 'All-Programs',
    component: <AllPrograms />,
  },
  {
    path: '/past-programs',
    exact: true,
    name: 'PastPrograms',
    component: <PastPrograms />,
  },
  {
    path: '/program-details',
    exact: true,
    name: 'ProgramDetails',
    component: <ProgramDetails />,
  },
  {
    path: '/report-symptoms',
    exact: true,
    name: 'ReportSymptoms',
    component: <ReportSymptoms />,
  }
]

export default routes
