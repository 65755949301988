import { memo, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../assets/images/close.svg'
import SuccessImage from '../assets/images/success.svg'


const AppointmentSuccess = memo(
  ({
    setSuccessModalOpen,
    callMessage,
    redirectedFrom,
    rescheduleCall,
    mhiScheduleMessage,
    scheduleMessage,
    handleModalClose
  }) => {
    const navigate = useNavigate();
    const scheduleCallSwitch = useMemo(() => {
      if (rescheduleCall) return callMessage
      if (scheduleMessage !== undefined) return scheduleMessage
      return mhiScheduleMessage
    }, [rescheduleCall, scheduleMessage])

    useEffect(() => {
      smartech('dispatch', 'Page Browse', {
        pageurl: redirectedFrom,
      })
    }, [])
    return (
      <div className="appointment-success-modal vh-100 text-center">
        <img
          alt="close-icon"
          className="close-icon m-2"
          role="presentation"
          src={CloseIcon}
          onClick={() => {
            setSuccessModalOpen(false)
            handleModalClose()
            setTimeout(() => {
              setSuccessModalOpen(false)
            }, 800)
          }}
        />
        <div className="position-absolute top-50 start-50 translate-middle">
          <img alt="sucess " src={SuccessImage} />
          <p className="mt-5 indigo-blue fw-bold f-20 line-24 mb-0">
            Congratulations!
          </p>
          <p className="indigo-blue f-16 line-24 fw-400 mt-4">
            {scheduleCallSwitch}
          </p>
        </div>
        <button
          className="w-90 connect-hc-btn py-3 fw-bold f-16 line-22 fixed-bottom"
          type="button"
          onClick={() => {
            setSuccessModalOpen(false)
            handleModalClose()
            setTimeout(() => {
              setSuccessModalOpen(false)
              navigate('/callback')
            }, 800)
          }}
        >
          Go Back to My Health
        </button>
      </div>
    )
  }
)

export default AppointmentSuccess
